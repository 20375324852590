.mobile-nav {
  &__wrapper {
    display: none;

    @media screen and (max-width: 768px) {
      position: fixed;
      width: 100%;
      height: 70px;
      bottom: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      background-color: var(--color-main-navigation-background);
      padding: 12px;
      box-shadow: 0 -5px 8px -2px rgba(0,0,0,.1);
      z-index: 10;
    }
  }

  &__button {
    border: none;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    gap: 0.25rem;
    color: var(--color-text-body);
    text-decoration: none;

    &.active {
      color: var(--color-scrappi);

      .mobile-nav__icon svg path {
        fill: var(--color-scrappi);
      }
    }
  }

  &__icon {
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: auto;
      height: 26px;

      path {
        fill: #969696;
      }
    }
  }

  &__label {
    font-size: 0.625rem;
    text-wrap: nowrap;
    color: #969696;
  }
}

.mobile-collection-icon {
  .mobile-nav__icon svg {
    width: 22px;
  }
}

@media screen and (max-width: 768px) {
  .main-navigation {
    .create-scrap-popup__wrapper {
      display: none;
    }
  }

  /* manage the height of the application content with the footer */
  .base-layout__content {
    > div,
    > section {
      height: calc(var(--screen-height) - (70px + 70px));
      /* make sure the scroll to top button doesn't hide controls */
      padding-bottom: 5rem;

      &.profile {
        padding-bottom: 0;
      }
    }
  }
}
