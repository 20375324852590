.public-navigation {
  display: flex;
  background-color: var(--color-main-navigation-background-dark);
  color: var(--color-text-light);
  position: sticky;
  left: 0;
  top: 0;
  /* min-height: 48px; */
  /* border-bottom: 1px solid rgba(112, 112, 112, 0.1); */
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 11px 1rem;
  height: 60px;

  z-index: 6;
  &__container {
    max-width: 90rem;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
  &__content {
    border-radius: 0;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    display: flex;
  }
  &__global-search {
    @media screen and (max-width: 1023px) {
      flex-grow: 1;
    }
  }

  &__search-wrapper {
    display: flex;
    align-items: center;
    gap: 2rem;
    @media screen and (max-width: 1023px) {
      gap: 16px;
      flex-grow: 1;
      margin-right: 16px;
    }
    @media screen and (max-width: 810px) {
      gap: 12px;
      margin-right: 12px;
    }
  }

  &__logo {
    a {
      display: flex;
    }
    &-mobile {
      height: 40px;
      width: auto;
      @media screen and (min-width: 1025px) {
        display: none;
      }
    }
    &-desktop {
      max-width: 115px;
      @media screen and (max-width: 1023px) {
        display: none;
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;
    gap: 6px;
    text-decoration: none;
    /* color: var(--color-text-light);
    font-weight: var(--font-weight-semi_bold);
    font-size: var(--font-smaller);
    line-height: 20px;
    width: max-content; */

    &-wrapper {
      display: flex;
      align-items: center;
      gap: 10px;
      @media screen and (max-width: 1023px) {
        gap: 16px;
      }
      @media screen and (max-width: 765px) {
        gap: 14px;
      }
    }
    &-label {
      background-color: rgba(56, 152, 236, 0);
      border: 1px solid #fff;
      border-radius: 10px;
      padding: 6px 24px;
      transition: color 0.4s, background-color 0.4s;
      color: #fff;
      line-height: 20px;
      cursor: pointer;
      text-decoration: none;
      display: inline-block;
      &:hover {
        color: #171717;
        background-color: #fff;
      }
      &-register {
        color: #fff;
        letter-spacing: -0.3px;
        background-color: #eb6f19;
        border-radius: 10px;
        margin-left: 0;
        margin-right: 0;
        padding: 6px 24px;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.5;
        cursor: pointer;
        text-decoration: none;
        &:hover {
          outline: 0;
        }
      }
    }
    svg {
      path {
        fill: var(--color-white);
      }
    }
  }

  .global-search-bar {
    &__input-wrapper {
      width: 360px;
      @media screen and (max-width: 1023px) {
        width: auto;
      }
    }
    &__search-button {
      display: block !important;
      background-color: var(--color-text-dark-200);
    }
  }

  .public-navigation__global-search {
    display: none;
  }
}

.public__loading {
  position: absolute;
  z-index: 9999999;
  height: 100%;
  width: 100%;
  font-size: 2.5rem;
  color: var(--color-scrappi);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(26, 26, 26, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  &-wrapper {
    position: relative;
    height: calc(var(--screen-height) - 75px);
    width: 100%;
  }
}
