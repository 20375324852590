.x-follows {
  &__wrapper {
    border-radius: var(--border-radius);
    color: var(--color-text-body);
    transform: translateY(0);
    transition: all 0.2s;
    overflow: hidden;
    border: var(--border-top-profile-card);
    margin-bottom: 0.5rem;

    a {
      text-decoration: none;
      color: var(--color-text-body);
    }

    &.small {
      .x-follows__user {
        padding: 0 0.5rem;
      }

      .x-follows__user.small {
        padding: 0.5rem;
      }

    }
  }

  &__instructions {
    color: var(--color-text-body);

    &--light {
      opacity: 0.5;
      font-size: 1.1rem;
    }

    .form-group {
      margin-top: 1.2rem;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--color-text-body);
    margin-bottom: 20px;
  }

  &__title {
    margin: 0;
  }

  &__link {
    text-decoration: none;
    color: var(--color-text-body);
  }

  &__user {
    display: flex;
    align-items: center;
    gap: 10px;
    text-decoration: none;
    padding: 0.5rem;

    &:hover {
      background-color: var(--color-background-hover);
    }

    img {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      object-fit: cover;
    }
    p {
      display: flex;
      flex-direction: column;
      gap: 5px;
      h2 {
        font-size: 16px;
        font-weight: 600;
        margin: 0;
      }
      h3 {
        font-size: 14px;
        font-weight: 500;
        margin: 0;
        opacity: 0.7;
      }
      span {
        font-size: 14px;
        font-weight: 400;
        opacity: 0.7;
      }
    }

    &.small {
      border-top: 1px solid var(--color-card-link-border-inner);
      align-items: flex-start;
      padding: 0.6rem;
      background-color: var(--color-background-hover);

      img {
        width: 30px;
        height: 30px;
      }

      h2 {
        font-size: 14px;
      }

      p {
        margin: 0;

        span {
          font-size: 0.75rem;
        }
      }

    }
  }

  &__error {
    color: var(--color-error);
  }

  &__loading,
  &__importing {
    color: var(--color-text-body);
  }
}

.ln-follows {
  color: var(--color-text-body);

  &__input-url {
    .label-text-input {
      margin-bottom: 1rem;
    }
  }

  &__file-uploader {
    color: var(--color-text-body);
  }

  &__section-disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  input[type='file'] {
    display: block;
    margin-bottom: 1rem;
  }
}

.google-contacts {
  .google-contact-auth {
    button {
      margin-top: 1rem;
      width: 100%;
      justify-content: center;
    }
  }
}

.google-contacts,
.device-contacts,
.ln-follows,
.x-follows {
  color: var(--color-text-body);
}
