
.navigation {
  position: fixed;
  width: 100%;
  z-index: 6;
  height: 70px;
  top: 0;

  &-sticky {
    @media screen and (max-width: 751px) {
      position: sticky;
      top: -70px;
    }
  }

  &__toggle {
    margin-right: 1rem;
    padding: 0.7rem 0;
    padding-top: 0;
    cursor: pointer;

    @media screen and (min-width: 751px) {
      display: none;
    }

    span {
      display: inline-block;
      width: 25px;
      height: 2px;
      background-color: var(--color-dark);
      position: relative;

      &::after,
      &::before {
        position: absolute;
        content: '';
        display: inline-block;
        background-color: var(--color-dark);
        width: 100%;
        height: 2px;
      }

      &::after {
        top: 7px;
      }

      &::before {
        top: -7px;
      }
    }

    &.show {
      span {
        background-color: var(--color-scrappi);
        &:before {
          background-color: var(--color-scrappi);
        }

        &:after {
          background-color: var(--color-scrappi);
        }
      }
    }
  }
}

.main-navigation {
  display: flex;
  background-color: var(--color-main-navigation-background);
  color: var(--color-main-navigation-text);
  left: 0;
  top: 0;
  height: 48px;
  /* width: 100%; */
  border-bottom: 1px solid rgba(112, 112, 112, 0.1);
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &--unauthenticated .sl-logo {
    display: inline-block;
  }
  &__options-wrapper{
    
    @media screen and (max-height: 416px){
      height: calc(var(--screen-height) - 80px);
      overflow: scroll;
    }
  
  }
  &__global-search {
    @media screen and (max-width: 480px) {
      width: 100%;
    }
    
  }
  &__account{
    /* color: var(--color-font-navigation-title);
    cursor: unset; */

    font-weight: var(--font-weight-medium);
    font-size: var(--font-smaller);
    line-height: 20px;
    padding: 7px 11px;
    padding-top: 0;
    display: inline-block;
    color: var(--color-font-navigation-title);
    &:hover {
      filter: brightness(1);
      
    }

  }
        
  /* &__account{
    color:rgba(75, 75, 75, 1)
  } */

  /* z-index: 5; */
  &.main-nav {
    z-index: 6;
  }
  padding: 6px 6px;
  height: 100%;

  &__union {
    cursor: pointer;
    display: none;
    &--force-show {
      display: block;
      margin-right: 14px;
      margin-left: 8px;
    }
    @media screen and (max-width: 768px) {
      display: block;
      cursor: pointer;
      margin-left: 2px;
      margin-right: 18px;
      min-width: 20px;
    }

    path {
      fill: var(--color-scrappi);
    }
  }
  /* &__union--active {
    path {
      fill: var(--color-scrappi);
    }
  } */

  &__user-details {
    display: flex;
    cursor: pointer;
    align-items: center;
    gap: 7.75px;
    margin-right: 8px;
    @media screen and (max-width: 950px) {
      display: none;
    }
  }

  &__user-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: var(--color-font-navigation-username);
  }

  &__arrow-icon {
    cursor: pointer;
    @media screen and (max-width: 768px) {
      display: none;
    }
    path {
      fill: var(--color-font-navigation-username);
    }
  }

  &__user-img {
    cursor: pointer;
    border-radius: 50%;
    border: 2px #eb6f19 solid;
    padding: 2px;
    width: 42px;
    height: 42px;

    @media screen and (max-width: 768px) {
      margin-right: 0;
    }
  }

  &__profile {
    display: flex;
  }

  &__create-scrap {
    position: relative;
  }
  &__avatar {
    line-height: 0;
  }
  &__bell {
    i {
      color: var(--icon-light);
      font-size: 28px;
    }
    @media screen and (max-width: 700px) {
      display: none;
    }
  }

  &__search {
    /* position: relative; */
    margin-right: 1rem;
    @media screen and (max-width: 450px) {
      margin-right: 0px !important;
      padding: 0 1rem;
      flex-grow: 1;
    }

    &-icon {
      margin: 0 1rem;
      @media screen and (max-width: 750px) {
        margin: 0 0.5rem;
      }
      &.active {
        color: #f15927;
      }
    }

    &-container {
      display: none;
      position: absolute;

      &.show {
        display: block;
        top: 0;
        right: 80%;
        width: 200px;
      }
    }
  }

  &__profile {
    position: relative;
    padding: 0 0.75rem;
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 0.75rem;
    border-radius: var(--border-radius);
    background-color: transparent;
    transition: 0.2s;

    &:hover {
      background-color: var(--color-background-hover);
    }

    &.active {
      color: var(--color-scrappi);
      @media screen and (min-width: 1000px) {
        border-bottom: 1px solid var(--color-scrappi);
      }
    }
    @media screen and (max-width: 960px) {
      font-size: 12px;
      margin-right: 0;
    }
    /* @media screen and (max-width: 450px) {
      display: none;
    } */
    &__my-account {
      white-space: nowrap;
      display: none;

      @media screen and (min-width: 1000px) {
        display: inline-block;
      }
    }

    & > span {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    a {
      &:focus {
        outline: none;
      }
    }

    &.active {
      color: var(--color-scrappi);
      font-weight: 500;
    }
  }

  &__menu {
    position: absolute;
    min-width: 197px;
    width: max-content;
    top: 100%;
    right: 4px;
    background-color: var(--color-hover-light);
    background: var(--color-background-navigation-dropdown);
    border-radius: 10px;
    filter: var(--filter-navigation-background-menu);
    &::after {
      content: '';
      position: absolute;
      top: -5px;

      width: 15px;
      height: 15px;

      background: var(--color-background-navigation-dropdown);
      transform: rotate(45deg);

      right: 35px;
      z-index: 0;
      /* border-top: solid 1px #ddd; */
      /* border-left: solid 1px #ddd; */
      
    }
    ul {
      padding: 0;

      li {
        list-style: none;
        button {
          border: none;
          background: transparent;
          text-align: start;
          width: 100%;
        }
        button,
        a {
          display: flex;
          
          text-decoration: none;

          font-weight: 500;
          font-size: 14px;

          color: var(--color-font-navigation);
          width: 195px;
          height: 35px;

          padding: 12px 20px 12px 12px;
          align-items: center;

          grid-gap: 4px;
          gap: 4px;
          /* isolation: isolate; */

          border-radius: 4px;

          margin-left: 4px;
          margin-right: 4px;
          

          &:hover {
            /* filter: brightness(0.8); */
            background: var(--color-theme-active-background);
            color: var(--color-scrappi);
          }
 
        }
       
       
        
        span {
         
          font-family: 'Inter';
          font-style: normal;



          font-family: 'Inter';
          font-style: normal;
        
          font-size: 14px;
          line-height: 20px;
          
        }


        
      }
    }
  
    .user {
      &--name {
        font-size: 1rem;
        color: white;
        background-color: rgba(0, 0, 0, 0.6);
        padding: 10px;
        margin-top: -0.5rem;
        border-radius: 10px 10px 0 0;
      }
    }
  }


  
  &__edit-text{
    
    
   }
   &__edit {
    min-height: 40px;
  }
  &__action {
    &-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      /* & > *:not(:last-child) {
        margin-right: 24px;
      } */
      @media screen and (max-width: 450px) {
        flex-grow: 0;
        & > *:last-child {
          margin-right: 0rem;
        }
        & > .main-navigation__create-scrap {
          margin-right: 0;
        }
      }

      &.open {
        .main-navigation__profile {
          background-color: var(--color-background-hover);
        }
      }
    }
  }

  /*
  NAVIGATON LINKS
  */
  &__links {
    display: flex;
    align-items: center;

    @media screen and (min-width: 1650px) {
      /* justify-content: space-between; */
    }
  }

  &__link-wrapper {
    display: none;
    margin-left: 2rem;
    &.show {
      z-index: 1;
      display: block;
      position: absolute;
      width: 100%;
      background: var(--color-white);
      top: 100%;
      border: var(--card-border);
      box-shadow: var(--shadow);
      margin: 0;
      left: 0;

      .active {
        &:after {
          display: none;
        }
      }

      > .main-navigation__link {
        width: 100%;
        padding: 13px 24px 11px;
        display: flex;
        gap: 8px;
      }
    }
    @media screen and (min-width: 1650px) {
      margin-left: 0;
    }

    @media screen and (min-width: 751px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__link {
    display: block;
    cursor: pointer;
    white-space: nowrap;
    position: relative;
    margin-right: 0.2rem;
    text-decoration: none;
    padding: 0.5rem 1.4rem;
    color: var(--color-text-body);

    &-icon {
      i {
        color: var(--icon-light);
        font-size: 24px;
      }
    }
    &-text {
    }
    /* 
    ADDED - Line height to navigation links 
    CHANGED - Color from #4d4d4d to #000000
    */
    line-height: 24px;
    color: var(--color-text-body);
    height: 100%;

    &.active {
      color: var(--color-scrappi);

      &:after {
        position: absolute;
        bottom: -5px;
        left: 0;
        content: '';
        display: inline-block;
        height: 2px;
        background: var(--color-scrappi);
        width: 100%;
      }
    }
  }
  &__close-button {
    cursor: pointer;
    margin-right: 1rem;
  }

  @media screen and (max-width: 1024px) {
   &__create-scrap {
      margin-top: 6px;
    }
    
  }
}



.sl {
  &-main-logo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    gap: 12px;

    width: 228px;
    height: 38px;
    a {
      line-height: 0;
    }
    @media screen and (max-width: 768px) {
      width: 0px;
    }
    @media screen and (max-width: 576px) {
      width: 0px;
    }
  }

  &-logo {
    display: inline-block;
    /* margin-left: 1rem; */
    width: auto;
    height: 28px;

    
    @media screen and (min-width: 760px) {
      height: 40px;
      padding: 0.3rem;
    }

    @media screen and (max-width: 768px) {
      &:not(.pages-div .sl-logo) {
        display: none;
      }
    }

    img {
      width: auto;
      height: 100%;
    }

    svg {
      height: 100%;
      width: auto;
    }
  }

  &-close-logo {
    cursor: pointer;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  &-close-logo-btn{
    margin-right: 5px;
    border-radius: 50%;
    border: none;
    background-color: transparent;
    padding:12px 15px;

    path {
      fill: var(--color-theme-10);
    }

    &:hover {
      background-color: var(--color-theme-active-background);
      path{
         fill: var(--color-scrappi);
      }
    }
  }
}

.add-topic {
  &__wrapper {
    position: relative;
    width: 40%;
  }

  &__input-wrapper {
    display: flex;
    position: absolute;
    width: 250px;
    left: 0;
    top: 100%;
    padding: 10px 15px 15px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 30px -8px rgba(0, 0, 0, 0.4);
  }

  &__icon {
    width: 35px !important;
    height: 35px !important;
    color: #f15927;
    transition: 0.3s;

    &.active {
      transform: rotate(45deg);
    }
  }

  &__input {
    height: 40px;
    max-width: 200px;
    border: 1px solid #c7cad1;
    -o-border-image: initial;
    border-image: initial;
    border-radius: 8px 0 0 8px;
    padding: 0 10px;
    transition: 0.1s;

    &:focus {
      outline-color: #f15927;
    }
  }

  &__save {
    display: flex;
    background-color: #f15927;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    color: white;
    margin-left: 5px;
    border-radius: 0 8px 8px 0;
    cursor: pointer;
  }
}

span.main-navigation__search-container.show .search-bar {
  right: -18px;
  top: -4px;
}


.hamburgericon{
  margin:10px
}