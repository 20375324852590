.scrap-add-notes-group {
  &__toggler-button {
    background-color: transparent;
    border: none;
    color: var(--color-scrappi);
    display: flex;
    gap: 8px;
    align-items: center;
    line-height: 20px;
    font-weight: var(--font-weight-semi_bold);
    padding: 0;
  }
  &__toggler-button-label {
    font-size: var(--font-smaller);
  }
  &__toggler-button-icon {
    path {
      fill: var(--color-scrappi);
    }
  }

  &__title-input {
    .label-text-input {
      &__input {
        border: 1px solid var(--color-theme-11) !important;
        &:focus {
          border: 1px solid var(--color-scrappi) !important;
        }
        z-index: 99;
        width: 100%;
        max-width: unset;
        padding: 8.75px 10px;
        font-size: var(--font-normal);
        font-weight: var(--font-weight-medium);
        &::placeholder {
          font-weight: var(--font-weight-medium);
          color: var(--color-theme-15);
          font-size: var(--font-smaller);
          line-height: 20px;
        }
        &:focus::placeholder {
          opacity: 0;
        }
      }
      
      
      &__label-active {
        display: inline-block;
      }
    }
  }

  &__description-input {
    margin-top: 10px;
    .tiptap {
      
      &-menu-bar {
        background: var(--color-background-base);
        padding:6px 10px;
        border-bottom: 1px solid var(--color-theme-11);
      }
      .ProseMirror[contenteditable='true'] {
        background: var(--color-background-base);
        min-height: 88px;
        max-height: 300px;
        border: 1px solid var(--color-theme-11);
      }
      .ProseMirror div.is-editor-empty:first-child::before {
        font-weight: var(--font-weight-medium);
        color: var(--color-theme-15);
      }
      .ProseMirror-focused {
        border: 1px solid var(--color-scrappi) !important;
        .is-empty.is-editor-empty {
            display: none;
        }
      }
      .label-text-input {
        &__label {
          display: none;
        }
        &__label-active {
          display: inline-block;
        }
      }
    }
  }
}
