


/*
 * Global styles
 */
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  background-color: var(--color-background-main);
  color: var(--color-font-primary);
  line-height: 1.4;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-break: break-word;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ql-editor {
  word-wrap: break-word;
}

/* Action icons */
.action-item {
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius);
  margin: 0.2rem;
  width: 36px;
  min-width: 36px;
  height: 36px;
  min-height: 36px;
  border: var(--card-border);
  border-radius: 50%;
  color: var(--color-font-primary);
  cursor: pointer;
  background-color: var(--color-white);
}
/* Time */
.time {
  &__stamp {
    font-size: var(--font-small);
    color: var(--color-text-body);
  }
}

/* page level title and headings */
.title {
  &__small {
    font-size: 0.85rem;
    color: #8f9ba7;
    font-weight: 700;
    margin: 1rem 0 0.2rem;
  }
}

input[type='text'] {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  height: 34px;
  padding: 0 1rem;
  line-height: 62px;
  width: 100%;
  font-size: 1rem;
  transition: 0.3s;
}
.textarea {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  border-color: #b1b1b1;
  border-radius: 0.8rem;
  min-height: 120px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-family: 'Inter', sans-serif;
}

.gallery-view__modal {
  .modal-window__body {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

/* global icon size */
.icon {
  &-normal {
    font-size: 16px;
  }

  &-big {
    font-size: 20px;
  }

  &-jumbo {
    font-size: 45px;
  }
}

.modal-window {
  padding: 0rem;
  border-radius: var(--border-radius);
  /* WIDTH PROBLEM CORRECTION */
  display: flex;
  flex-wrap: wrap;
  & > div {
    width: 100%;
  }
  &__close {
    cursor: pointer;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--color-very-light-pink-50);
    padding: 0.5rem 0.8rem;
    height: 50px;
    /* margin-bottom: 0.8rem; */
  }

  &__heading {
    font-size: 1.4rem;
    font-weight: 700;
    margin: 0;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    span {
      color: var(--color-font-light);
      margin-left: 0.8rem;
      font-weight: 400;
      vertical-align: top;
      font-size: var(--font-large);
    }
  }
  &__form {
    text-align: start;
    .char-limit {
      color: var(--color-font-light);
      font-size: var(--font-small);
    }
  }
  &__body {
    padding: 16px 24px;
    text-align: center;
    &.no-padding {
      padding: 0rem;
    }
    min-height: auto;
    max-height: calc(var(--screen-height) - 50px);
  }

  &__actions {
    display: flex;
    padding: 11px 23px 11px 33px;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    border-top: var(--card-border);
    &-secondary {
    }
    &-primary {
      display: flex;
      gap: 12px;

      &-close {
        flex-grow: 1;
      }
      &-cancel {
        flex-grow: 1;
      }
      &-cancel.button__outline {
        color: var(--color-text-dark);
      }
      .button__secondary {
        margin-right: 0.5rem;
        background: transparent;
        color: darkslategrey;
        padding: 0;
        text-decoration: underline;
      }
      .button__primary:disabled {
        background-color: grey;
      }
    }

    @media screen and (max-width: 450px) {
      padding: 11px 1rem;
      &-primary {
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
        .button__primary {
          flex-grow: 1;
        }
      }
      &-secondary {
        display: none;
      }
    }
  }
  &__message {
    color: red;
  }
}

@media screen and (max-width: 710px) {
  .modal-window {
    position: fixed;
    bottom: 0;
    left: 0;
    height: fit-content;
    align-content: space-between;
    max-width: unset !important;
    width: 100% !important;
  }
  .modal-window__actions {
    align-self: end;
  }
  .modal-window {
    background-color: white;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .modal-window__body {
    height: calc(100% - 105px);
    overflow: auto;
  }
}

/* Hover State */

.hoverState__with-white-text{
  &:hover{
    background-color: var(--color-scrappi) !important;
    color: #fff !important;
    path{
      fill:#fff !important;
    }
  }
}
.hoverState__with-white-text-stroke{
  &:hover{
    background-color: var(--color-scrappi) !important;
    path{
      fill:unset;
      stroke:#fff !important;
    }
  }
}

.hover-btn{
  border-radius: 50%;
  border:none;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
      background-color: var(--color-theme-active-background);
      path{
         fill: var(--color-scrappi);
      }
    }

  @media(max-width:767px){
    width: unset;
    height: unset;
    padding-left:0;
    padding-right:0;
  }
}

.hoverState-logo{
  &:hover {
      background-color: var(--color-theme-active-background);
      path{
         fill: var(--color-scrappi);
      }
    }
}

.hoverState-text-color{
  &:hover {
    color: var(--color-scrappi);
  }
}

.hoverState-button{
  &:hover{
    background-color: var(--color-scrappi--hover) !important;
  }
}

.hoverState-text-svg{
  &:hover {
    color: var(--color-scrappi);
    path{
       fill: var(--color-scrappi);
    }
  }
}

.hoverState-text-svg-stroke{
  &:hover {
    color: var(--color-scrappi);
    path{
      fill:unset;
       stroke: var(--color-scrappi) !important;

    }
  }
}
