.trust {
  &-btn {
    background: transparent;
    color: var(--color-scrappi);
    transition: 0.3s;
    border: 1px solid var(--color-scrappi);
    padding: 5px 5px;
    border-radius: 8px;
    display: flex;
    flex-wrap: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;

    /* disabled attribute of the button */
    &[disabled] {
      cursor: not-allowed;
      opacity: 0.5;
    }


    &__icon {
      display: flex;

      svg {
        width: 20px;
        height: 20px;

        path {
          fill: var(--color-scrappi);
        }

        path.shield {
          fill: var(--color-scrappi);
        }
      }
    }

    &--trusted {
      background: var(--color-scrappi);
      color: var(--color-white);
      border: 1px solid transparent;

      .trust-btn__icon {
        svg {
          path {
            fill: var(--color-white);
          }
        }
      }
    }
  }

  &__text {
    display: flex;
    width: 100%;
  }


  &__icon {
    position: relative;
    line-height: 18px;
    font-weight: normal;

    svg {
      width: 18px;
      height: 18px;

      path.shield {
        fill: var(--color-scrappi);
      }
    }
  }
}

/* style for different pages */
.profile-card__clntname {
  .trust {
    &__icon {
      top: 3px;
      left: 5px;
    }
  }
}

/* scrap */
.scrap-card__user-full-name {
  .trust {
    &__icon {
      svg {
        position: relative;
        top: 2px;
        height: 14px;
      }
    }
  }

}

.new-collection-header__owner-details__display-name,
.contact-card__user-name,
.contact-card__user-id {
  .trust {
    &__icon {
      top: 2px;
      left: 3px;

      svg {
        width: auto;
        height: 14px;

        path {
          fill: var(--color-white);
        }

        path.shield {
          fill: var(--color-scrappi) !important;
        }
      }
    }
  }
}

.new-collection-header__owner-details__display-name,
.contact-card__user-name {
  padding-right: 5px;
}

/* make sure the trust button is clickable */
.scrap-card__footer-dropdown-item-label,
.dropdown__item-label {
  display: flex;
  width: 100%;
  align-items: center;
}

.dropdown__item-icon {
  svg {
    path.shield-check {
      fill: var(--color-white);
    }
  }
}

.scrap-card__footer-dropdown-item-icon {
  svg {
    path.shield-check {
      fill: var(--color-white);
    }
  }
}

/* hover state */
.scrap-card__footer-dropdown-item:hover .scrap-card__footer-dropdown-item-icon svg path.shield-check {
  fill: var(--color-white);
}

/* Icon size in the dropdown */
.dropdown__item,
.scrap-card__footer-dropdown-item {
  .trust-icon {
    width: 20px;
    height: 20px;
  }
}

.x-follows__user {
  .trust-icon {
    width: 15px;
    height: 15px;
  }
}