.card {
  &__collection-tag-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    margin-bottom: 12px;
  }
  &__user {
    margin-bottom: 11px;
    align-items: flex-start;
    display: flex;
    gap: 8px;
    &-avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    &-initials {
      position: relative;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      color: var(--color-white);
      background-color: var(--color-scrappi);
      font-weight: var(--font-weight-bold);
      /* display: flex; */
      &::after {
        font-size: var(--font-normal);
        font-weight: var(--font-weight-bold);
        position: absolute;
        content: attr(data-initials);
        display: inline-block;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        white-space: nowrap;
      }
    }

    &-meta {
      /* width: calc(100% - 50px); */

      width: calc(100% - 50px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &__date-time {
        display: flex;
        padding: 0px;
        grid-gap: 10px;
        gap: 10px;
        margin-top: 5px;
        .card__user-updated-at {
          display: flex;
          align-items: center;
          flex-direction: row;
          align-items: center;
          padding: 0px;
          grid-gap: 4px;
          gap: 4px;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: var(--color-card-font-user-name);
          opacity: 1;
        }
        .card__created-at {
          svg path {
            fill: unset;
            stroke: var(--color-card-font-user-name);
          }
        }
        .card__updated-at {
          svg path {
            fill: var(--color-card-font-user-name);
          }
        }
      }
    }
    &-names-wrapper {
      display: flex;
      column-gap: 4px;
      row-gap: 0px;
      font-size: var(--font-smaller);
      line-height: 18px;
      flex-wrap: wrap;
    }
    &-names-wrapper &-name {
      display: inline-block;
      font-weight: var(--font-weight-medium);
      font-size: var(--font-smaller);
      line-height: 18px;
    }
    &-full-name {
      color: var(--color-card-meta-text);
      font-size: var(--font-smaller);
      line-height: 18px;
      font-weight: var(--font-weight-bold);
      display: inline;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-updated-at {
      font-size: var(--font-smaller);
      line-height: 17px;
      font-weight: var(--font-weight-medium);
      color: var(--color-card-meta-text);
      opacity: 0.5;
    }
    &-updated-at,
    &-name {
      color: var(--color-card-font-user-name);
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &-base&__with-user {
    .meta .meta__link {
      bottom: 7rem;
    }
  }
  &-gallery {
    position: relative;

    &__images {
      position: relative;
      width: 100%;
      overflow: hidden;
      cursor: pointer;
      border-radius: var(--border-radius-small);
      border: var(--border-card-outer);
      height: 300px;
      margin-bottom: 12px;
      img {
        object-fit: cover !important;
      }
      .three-images-thumbnail {
        height: 100%;
        display: flex;
        flex-wrap: nowrap;

        & > div {
          width: 50%;
          display: flex;
        }

        & > div:first-child {
          margin-right: 1px;

          .image-1 {
            width: 100%;
            height: 100%;
          }
        }
        & > div:last-child {
          display: flex;
          flex-direction: column;
          height: 100%;
          margin-left: 1px;

          .image-2 {
            height: 50%;
            margin-bottom: 1px;
          }

          .image-3 {
            width: 100%;
            height: 100%;
            margin-top: 1px;
          }
        }

        &__more {
          display: flex;
          height: 50%;
          width: 100%;
          position: relative;
        }
      }

      .two-images-thumbnail {
        height: 100%;
        display: flex;
        height: 100%;

        & > div {
          width: calc(50%);
        }

        & > div:first-child {
          margin-right: 1px;

          .image-1 {
            width: 100%;
            height: 100%;
          }
        }
        & > div:last-child {
          margin-left: 1px;
          display: flex;
          flex-direction: column;

          .image-2 {
            height: 100%;
            width: 100%;
          }
        }
      }
      .one-image-thumbnail {
        height: 100%;
        display: flex;
        & > div {
          width: 100%;
          .image-1 {
            width: 100%;
            height: 100%;
          }
        }
      }
      .no-image-thumbnail {
        height: 100%;
        background-color: #efefef;
      }
    }

    /* border */
    .three-images-thumbnail,
    .two-images-thumbnail,
    .one-image-thumbnail {
      border: var(--border-card-base);
      border-radius: var(--border-radius-small);
      overflow: hidden;
    }

    &__count {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
      background: rgba(0, 0, 0, 0.6);
      font-size: 1.75rem;
      color: var(--color-white);
      font-weight: var(--font-weight-bold);
      border-bottom-right-radius: var(--border-radius-small);
    }
    .card__more-content-body {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }

  &-base {
    position: relative;
    flex: 1;
    border-radius: var(--border-radius);
    transition: 0.3s ease-in-out;
    transition-property: box-shadow, transform;
    flex: 0 1 calc(100% - 1rem);
    overflow: visible;
    box-shadow: var(--card-shadow);
    padding: 10px 9px;
    transform: translateY(0);
    cursor: pointer;
    min-height: 80px;

    background: var(--color-card-base);
    color: var(--color-card-text);
    border-top: var(--border-shadow-card);

    &.with-url .card__more-content-ugc .card__notes-display {
      padding-top: 0;
    }

    &.card__social,
    &.card__annotation,
    &.with-url,
    &.card__product {
      background: var(--color-card-new-base-other);
      border-top: var(--border-shadow-card-other);
      .meta {
        background: var(--color-card-new-meta-other);
        box-shadow: inset 0 2px 1px -1px var(--border-card-outer--color);
        border-top: 1px solid var(--color-card-meta-other);
        &__comment-icon path,
        .share-settings svg path,
        &__edit-button path {
          fill: var(--color-icon-meta-card-settings-other);
        }
        &__edit-button--trash {
          path {
            fill: unset;
            stroke: var(--color-icon-meta-card-settings-other);
          }
        }
      }
      .card__more-content {
        &--ugc {
          background: var(--color-card-new-base-other);
        }
        &-body {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          &--ugc {
            background: var(--color-card-new-base-other);
          }
        }
        &-footer {
          border-top: 1px solid var(--color-card-meta-other);
          background: var(--color-card-new-meta-other);
          box-shadow: inset 0 2px 1px -1px var(--border-card-outer--color);
        }
        &-close-button {
          svg {
            path {
              fill: var(--color-icon-meta-card-settings-other);
            }
          }
        }
      }
      .card-settings {
        &__toggler {
          i {
            color: var(--color-icon-card-settings--other);
          }
          &:hover {
            background-color: var(--color-card-base-other);
          }
        }
      }
    }
    &.card__annotation {
      .card-link-preview__container {
        margin-bottom: 12px;
        .ProseMirror {
          color: var(--color-annotation-text);
        }
        .card__highlight {
          padding-bottom: 0px;
        }
      }
      .ProseMirror * {
        font-size: var(--font-smaller);
        line-height: 18px;
        font-weight: var(--font-weight-normal);
      }
      .card-link-preview__link {
        margin-top: unset;
      }
    }

    &.with-url .card__notes-display .card__content {
      background-color: var(--background-card-notes);
      padding: 12px;
      border-radius: var(--border-radius-small);
      border-left: 4px solid var(--color-link-text);
      position: relative;
    }
    .card__notes-title {
      margin-left: 14px;
      line-height: 20px;
      font-size: var(--font-normal);
      font-weight: var(--font-weight-medium);
      padding-right: 2rem;
      word-break: break-word;
    }
    .card__notes-display {
      margin-top: 0.5rem;
      .card__content {
        margin-bottom: 12px;
        position: relative;
        .card__description {
          &-content {
            font-size: var(--font-smaller);
            line-height: 18px;
            font-weight: var(--font-weight-normal);
            .LinesEllipsis {
              &-ellipsis button {
                font-size: var(--font-smaller);
                line-height: 18px;
                font-weight: var(--font-weight-normal);
                background-color: transparent;
                border: none;
                color: var(--color-scrappi);
                padding: 0;
              }
            }
            pre {
              white-space: pre-wrap;
            }
            mark {
              background-color: #ffc078 !important;
              padding: 0.125em 0;
              border-radius: 0.25em;
              -webkit-box-decoration-break: clone;
            }
            * {
              font-size: var(--font-smaller);
              line-height: 18px;
            }
          }
          &-expand-button {
            position: absolute;
            right: 0;
            bottom: 0;
            font-size: var(--font-smaller);
            line-height: 18px;
            font-weight: var(--font-weight-normal);
            background-color: transparent;
            border: none;
            color: var(--color-scrappi);
          }
        }
      }
    }

    a {
      color: var(--color-card-text);
      text-decoration: none;
      pointer-events: none;
    }
    a {
      color: var(--color-link-color);
      text-decoration: underline;
      pointer-events: all;
    }
    .card__content .tiptap {
      color: var(--color-text-body);
    }

    .card-overlay {
      position: absolute;
      background-color: transparent;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 196px;
    }

    /* responsive styles */
    @media screen and (min-width: 960px) {
      flex: 0 1 calc(50% - 1rem);
    }

    @media screen and (min-width: 1080px) {
    }

    @media screen and (min-width: 1200px) {
      flex: 0 1 calc(33.3% - 1rem);
    }

    @media screen and (min-width: 1440px) {
      flex: 0 1 calc(33.3% - 1.6rem);
    }

    &:hover {
      /* box-shadow: 0 8px 35px -10px rgba(0, 0, 0, 0.25); */

      &:after {
        pointer-events: none;
        content: '';
        position: absolute;
        top: -2px;
        left: 0;
        width: 100%;
        height: calc(100% + 2px);

        background: linear-gradient(
          rgba(0, 0, 0, 0.1) 10%,
          transparent,
          transparent,
          transparent,
          transparent,
          transparent,
          rgba(0, 0, 0, 0.1) 90%
        );
        border-radius: var(--border-radius);
      }
    }

    /* meta style in cards */
    .meta {
      position: relative;
      width: calc(100% + 18px);
      bottom: 0;
      left: 0;
      margin: 0 -9px -0.75rem;
      padding: 0.5rem;
      height: 35px;
      background: var(--color-card-new-meta);
      border-radius: 0 0 var(--border-radius) var(--border-radius);
      transform: translateY(-2px);
    }

    &__notes {
      background-color: var(--color-scrappi);
      filter: brightness(90%);
      color: #ffffff;
      height: 260px;

      .card__content {
        height: calc(100% - 1.2rem);
        padding: 1.3rem;
      }

      &:hover {
        box-shadow: var(--shadow);
        transform: translateY(-5px);
      }
    }

    .img__main {
      max-width: 100%;
      height: 100%;
      max-height: 500px;
      display: block;
      object-fit: contain;
      margin: auto;
      &-div {
        overflow: hidden;
        position: relative;
        z-index: 1;
        display: flex;
        width: 100%;
        left: 0.75rem;
        border-radius: var(--border-radius-small);
        border: var(--border-card-content-inner);
        /* background-color: #dddbdd; */
        left: 0;
        max-height: 500px;
        min-height: 150px;
      }
      /* Apply back ground color only if scrap is not displayed in story view in my scraps page */

      &-background {
        background-size: cover !important;
        background-position: center !important;
        width: 100%;
        height: 100%;
        position: absolute;
        overflow: hidden;
        border-radius: var(--border-radius);
        top: 0;

        &::before {
          content: '';
          background: rgba(255, 255, 255, 0.4);
          width: 100%;
          height: 100%;
          backdrop-filter: blur(7px);
          display: block;
          position: absolute;
        }
      }
    }

    /* hide the label "Tags" from the scrap card view */
    .tags-list__wrapper {
      .title__small {
        display: none;
      }
    }
    .tags-view {
      &--with-icon {
        .tags-view {
          &__label-wrapper {
            .tags-view__icon svg {
              width: 12px;
              height: 12px;
            }
          }
        }
      }
    }
  }
  &__n &__product-price {
    font-size: 1.1rem;
    font-weight: 900;
    margin-top: 1rem;
  }

  &__content {
    /* color: var(--color-font-light); */
    font-size: var(--font-normal);
    line-height: 1.5;

    .ql-container.ql-snow {
      border: none;
      font-size: 1rem;
    }
    .ql-editor {
      overflow: hidden;
      padding: 0;
    }
  }
  &__title-and-settings-wrapper {
    position: relative;
    margin-bottom: 0.5rem;
  }

  &__settings {
    z-index: 1;
  }

  &-settings {
    &__toggler {
      padding: 0.2rem;
      border-radius: 50%;

      &:hover {
        background-color: var(--color-card-base);
        color: var(--color-card-text);
      }
    }
  }
  &__settings + &__page-title {
    padding-right: 2.5rem;
  }

  &__page-title {
    line-height: 20px;
    font-size: var(--font-normal);
    font-weight: var(--font-weight-medium);
    margin: 0.75rem 0 0.5rem;
    padding-right: 2rem;
    word-break: break-word;
  }

  &__url {
    a {
      text-decoration: none;
      color: inherit;
    }
    &-name {
      font-style: italic;
      font-size: 0.8rem;
      word-break: break-word;
    }
  }

  &__time-spent {
    font-weight: normal;
    opacity: 0.5;
    margin-top: -0.8rem;
  }

  &__image {
    height: 260px;
    background-size: cover;
    background-position: center;

    &-grouped {
      height: 100%;
      background-size: cover;
      background-position: center;
    }
  }

  &__content {
    margin-bottom: 1rem;
    p {
      margin: 0;
    }

    &--video {
      height: auto;
      padding: 2rem;
      word-break: break-word;

      &-count {
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        width: 100%;
        height: 150px;
        background-color: rgba(0, 0, 0, 0.85);
        /* filter: blur(1px); */
        color: white;
        justify-content: center;
        align-items: center;

        .video-count {
          font-size: 1.6rem;
          position: relative;

          &:before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: -20px;
            display: inline-block;
            width: 0;
            height: 0;
            border-top: 6px solid transparent;
            border-bottom: 6px solid transparent;
            border-left: 10px solid white;
            margin: auto;
          }
        }
      }

      /* TITLE STYLE OF THE VIDEO GROUP */
      .card-group__title {
        font-size: 1.5rem;
      }
    }

    &--comment {
      border: 1px solid #00000026;
      border-radius: 8px;
      padding: 15px;
    }

    h3 {
      color: #414141;
      font-weight: normal;
      font-size: 1.5rem;
    }
  }

  &__highlight {
    font-size: 1rem;
    line-height: 1.5rem;
    padding: 0.75rem;
    background-color: var(--color-background-card-highlights);
    color: var(--color-text-body);
    border-left: 5px solid var(--color-yellow);
    border-radius: var(--border-radius-small);

    &--wrapper {
     overflow-y: inherit;
    }

    mark {
      background-color: transparent;
      font-weight: 400;
    }
  }

  &__source {
    padding: 0 8px;
    display: block;
    text-decoration: none;
    font-size: var(--font-smaller);
    color: var(--text-active);
    line-height: 1.5;
    text-align: center;
    margin-top: 5px;
    border: solid 1px transparent;
    &:hover {
      border-radius: 4px;
      border: solid 1px #0e8bbd;
      background-color: rgba(14, 139, 189, 0.09);
    }
  }

  &__link {
    display: none;
    position: absolute;
    right: 0;
    top: -16px;
    width: 0;
    height: 0;
    border-top: 35px solid transparent;
    border-bottom: 35px solid transparent;
    border-left: 35px solid;
    border-left-color: yellow;
    transform: rotate(-45deg);
    cursor: pointer;
    z-index: 4;
  }

  /* GROUP STYLE */
  &-group {
    flex: 0 1 calc(100% - 1rem);
    padding-bottom: 0;
    display: inline-block;

    a {
      text-decoration: none;
      color: inherit;
    }

    @media screen and (min-width: 960px) {
      flex: 0 1 calc(50% - 1rem);
    }

    @media screen and (min-width: 1080px) {
    }

    @media screen and (min-width: 1200px) {
      flex: 0 1 calc(33.3% - 2rem);
      max-width: calc(33.3% - 2rem);
      margin: 2rem 1.2rem;
    }

    @media screen and (min-width: 1440px) {
      flex: 0 1 calc(25% - 2rem);
      max-width: calc(25% - 2rem);
      margin: 1.2rem 1.1rem;
    }

    &:before {
      z-index: -1;
    }

    &:after {
      z-index: -2;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 260px;
      background-color: white;
      transform-origin: center bottom;
      transform-origin: 50% 100%;
      border-radius: 10px;
      transform: translate(2.5px, 0) rotate(2.5deg);
    }

    &:before {
      opacity: 0.9;
      transform: translate(-1px, 0) rotate(-1deg);
      box-shadow: 0 2px 5px -4px rgba(0, 0, 0, 0.2);
      transition: 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
    &:after {
      opacity: 0.6;
      transform: translate(-2px, 0) rotate(-2deg);
      box-shadow: 0 8px 15px -4px rgba(0, 0, 0, 0.3);
      transition: 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

    &__wrapper {
      position: relative;
      width: 100%;
      height: 260px;
      background: white;
      border-radius: 10px;
      box-shadow: 0 4px 14px -4px rgba(0, 0, 0, 0.15);
      overflow: hidden;
      transition: 0.3s;
    }

    &:hover {
      &:before {
        transform: translate(-2.5px, 0) rotate(-2deg);
        box-shadow: 0 15px 30px -6px rgba(0, 0, 0, 0.15);
      }
      &:after {
        transform: translate(-4px, 0) rotate(-5deg);
        box-shadow: 0 15px 50px -8px rgba(0, 0, 0, 0.15);
      }
    }

    &__title {
      font-size: 1.1rem;
      font-weight: 700;
      margin: 0;
      word-break: break-word;
    }

    &__count {
      position: relative;
      margin-top: 5px;
      width: 100%;
      bottom: 0;
    }
  }

  &-image {
    width: 100%;
    height: auto;
  }

  /* CARD ICONS */
  &-icon {
    display: inline-block;
    padding: 2px;
    margin-right: 20px;
    padding-left: 25px;
    background-repeat: no-repeat;
    background-size: 18px 18px;
    background-position: center left;
    font-size: 0.9rem;
    opacity: 0.7;

    &--screenshot {
      background-image: url(/img/screenshot.svg);
      /* background-image: url(http://localhost:8000/img/screenshot.svg); */
    }
    &--video {
      background-image: url(/img/video.svg);
      /* background-image: url(http://localhost:8000/img/video.svg); */
    }
    &--annotation {
      background-image: url(/img/annotation.svg);
      /* background-image: url(http://localhost:8000/img/annotation.svg); */
    }
  }

  &__playback {
    padding-left: 10px;
  }

  &__social {
    &--service {
      overflow: hidden;

      &.youtube {
        /* height: 225px; */
      }
    }

    iframe:not(.tiktok iframe) {
      width: 100% !important;
      /* min-width: 250px !important; */
      /* height: 300px !important; */
    }
    .facebook iframe {
      padding-right: 10px;
    }
    .tiktok iframe {
      width: 350px !important;
      height: 770px !important;
    }
  }

  &__audio {
    audio {
      width: 100%;
    }

    .audio-player__wrapper {
      background-color: var(--color-white);
      border: var(--border-card-content-outer);
      border-radius: var(--border-radius-small);
      line-height: 0;
    }
    .audio-player__inner-wrapper {
      border: var(--border-card-content-inner);
      border-radius: var(--border-radius-small);
      height: 70px;
      &::-webkit-media-controls {
        align-items: center;
        justify-content: center;
        color: black;
      }

      &::-webkit-media-controls-panel {
        background-color: var(--color-white);
        box-shadow: none;
        border: none;
      }
    }
  }

  /* ANNOTATION SPECIFIC RULES */
  &__annotation,
  &__screenshot {
    .card__content {
      padding-top: 0;
    }
    .card__more-content-body {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }

  &__page {
    &-content {
      display: flex;
    }

    &-info {
      padding-left: 1.5rem;

      .card__page-title {
        margin: 0;
      }
    }

    .img__page {
      width: 40%;
      height: auto;
      object-fit: cover;
      border-top-left-radius: 1.2rem;
      margin: -0.75rem 0 0 -0.75rem;
    }
  }
  &__document-outer-container {
    border: var(--border-card-content-outer);
    border-radius: var(--border-radius-small);
    margin-bottom: 12px;
  }

  &__document {
    border-radius: var(--border-radius-small);
    border: var(--border-card-content-inner);
    width: 100%;
    height: 7rem;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 16px 10px 16px 16px;
    background: #f6f6f6;
    text-align: center;
  }
  &__document-link {
    font-size: 0.9rem;
    color: #495057;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  &__document-icon {
  }
  &__document-file-name {
    color: #797979;
    font-weight: var(--font-weight-medium);
    font-size: var(--font-small);
    line-height: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
  }

  &__collection {
    display: flex;
    align-items: center;
    gap: 8px;
    padding-left: 0;
    .tags-view {
      &__icon {
        svg {
          width: 14px;
          height: 14px;
        }
      }
      &__single-line-list {
        display: flex;
        flex-direction: column;
        gap: 3px;
        margin-left: 0px;
      }
    }
  }

  &__collection-icon {
    height: 20px;
    width: 20px;
  }

  &__collection-title {
    color: var(--color-text-body);
    font-weight: var(--font-weight-light);
    font-size: var(--font-smaller);
  }
  &__more-content {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: var(--color-card-new-snippet);
    transform: translateY(100%);
    transition: transform 0.3s;
    &--visible {
      pointer-events: all;
      transform: translateY(0);
    }

    &--meta {
    }
    &--ugc {
      .card__notes-display {
        margin-top: 0;
      }
    }
    &-all-files{
      margin-bottom: 8px;
    }
    &-overlay {
      opacity: 0;
      display: flex;
      align-items: flex-end;
      position: absolute;
      border-radius: var(--border-radius);
      bottom: 0;
      left: 0;
      pointer-events: none;
      height: 100%;
      width: 100%;
      overflow: hidden;
      transition: opacity 0.3s;
      &--visible {
        pointer-events: fill;
        z-index: 1;
        opacity: 1;
      }
    }
    &-body {
      height: calc(100% - 35px);
      overflow: auto;

      &--meta {
        /* height: 100%; */
        background-color: var(--color-card-new-snippet);
      }
      &--ugc {
        background: var(--color-card-base);
        padding-bottom: 14px;
      }
    }
    &-meta{
      padding: 8px;
      background-color: var(--color-card-new-snippet);
      margin-bottom: 8px;
      border-radius: 4px;
    }
    &-all-data {
      padding: 14px 14px 0px 14px;
      .card__highlight--wrapper {
        margin-bottom: 8px;
      }

      .card-link {
        &-preview {
          &__container {
            border: none;
            border-radius: 0;
          }
          &__wrapper {
            border: none;
            border-radius: 0;
            padding: 0;
          }
          &__link {
            margin-top: 0;
          }
        }
        &__target {
          padding: 0;
        }
      }
    }
    &-ugc {
      display: flex;
      flex-direction: column;
      gap: 14px;
      overflow-y: inherit;

      .card__more-content-tags {
        padding-left: 14px;
        padding-right: 14px;
      }

      .card__collection,
      & > .card__more-content-tags,
      .card__content:not(.card-base.with-url
          .card__notes-display
          .card__content) {
        padding: 0 14px;
      }
      /*& > *:first-child {*/
      /*  padding-top: 14px;*/
      /*}*/
    }
    &-footer {
      position: relative;
      height: 35px;
      background: var(--color-card-new-meta);

      box-shadow: inset 0 2px 1px -1px var(--border-card-outer--color-blue);
      border-top: 1px solid var(--color-card-meta);
    }
    &-close-button {
      position: absolute;
      border: none;
      background-color: transparent;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      svg {
        height: 20px;
        width: 20px;
        path {
          fill: var(--color-icon-card-settings);
        }
      }
    }
  }
}

.with-entry card-base {
  flex: 0 1 calc(100% - 2rem);
  margin: 0.5rem 1rem;
  min-width: calc(100% - 2rem);
}

a.card-base {
  text-decoration: none;
  color: inherit;
}

a.notes-link {
  text-decoration: none;
  color: inherit;
}

/* MESSAGES FOR ACTIONS ON THE PAGE */
.action {
  &__message {
    width: 100%;
    background-color: #efefef;
    display: flex;
    height: 75px;
    margin-bottom: 1.2rem;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    letter-spacing: 1.9px;
    font-size: 0.9rem;
    opacity: 0.7;
  }

  &__confirm {
    display: flex;
    border-radius: 0;

    &--yes,
    &--no {
      &.button__actions {
        padding-top: 5px;
        padding-bottom: 5px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        margin-left: 2px;
      }
    }

    &--no {
      &.button__actions {
        color: black;
        border-radius: 0 30px 30px 0;
      }
    }

    &--yes {
      &.button__actions {
        background-color: #e35656;
        border-color: #e35656;
        color: white;
        border-radius: 30px 0 0 30px;
      }
    }
  }
}

.error {
  &__message {
    font-size: 0.9rem;
    font-style: italic;
    color: #e35656;
  }
}

/* BUTTONS */
.button {
  &__actions {
    display: inline-block;
    padding: 10px 20px;
    display: flex;
    max-width: 175px;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    cursor: pointer;
    background-color: white;

    &:hover {
      filter: contrast(0.9);
    }

    &:focus {
      outline: none;
    }

    &.active {
      filter: contrast(0.8);
    }
  }

  &__error {
    background-color: #e35656;
    border: 1px solid #e35656;
    color: white;
    border-radius: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.notes-icon {
  width: 75px !important;
  height: 75px !important;
  &:not(.card__document &) {
    margin-bottom: 2rem;
  }
}

.auto-generate {
  &__label {
    font-size: 0.8rem;
    opacity: 0.6;
    margin-left: 1rem;
  }
}

/* STYLES FOR THE COMPACT GRID */
.compact {
  .img__main {
    &-div,
    &-background {
      max-height: 500px;
      height: 100%;
    }
  }

  .card__page {
    .img__main {
      &-div,
      &-background {
        max-height: 500px;
        height: 100%;
      }
    }
  }
}
.cloudflare__video iframe {
  height: 250px !important;
}

.card-video {
  &__loading-overlay {
    position: fixed;
    z-index: 9999999;
    height: 100%;
    width: 100%;
    font-size: 2.5rem;
    color: var(--color-scrappi);
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(26, 26, 26, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }
}
/* .card__collection .tags-view__icon svg {
  width: 14px;
  height: 14px;
}
 .tags-view__icon svg {
  width: 14px;
  height: 14px;
 } */

.card-base {
  &.card__general-note,
  &.card--document,
  &.card-gallery {
    .meta .meta__wrapper .meta__left .collection-card__footer-visibility {
      svg path {
        fill: var(--color-icon-card-settings);
      }
    }
  }
}

.meta .collection-card__footer-visibility {
  svg path {
    fill: var(--color-icon-meta-card-settings-other);
  }
}

.tag-collection-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  margin-bottom: 12px;
}