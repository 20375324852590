.matching-scraps {
  margin-bottom: 1.5rem;
  min-height: 300px;

  .scrap-card {
    width: 100%;
    margin-bottom: 1rem;

    @media (max-width: 768px) {
      max-width: 100%;
    }
  }

  /* for cases where the user data is not available in the Scrap */
  .user-na {
    .avatar__user-avatar,
    .scrap-card__dot,
    .scrap-card__user-name {
      display: none;
    }

    .scrap-card__header {
      padding-bottom: 0;
    }
  }

  &-popup-content {
    .new-popup__body {
      background: var(--color-background);
      padding: 1rem;
    }
  }
}