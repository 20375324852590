.embedo-item {
    height: 100% !important;
    &--youtube {
        width: 100%;
        height: 100%;
    }
    div {
        height: inherit;
    }

}

.render-embed{

    &__loading-overlay {
      position: absolute;
      z-index: 9999999;
      height: 110%;
      width: 100%;
      font-size: 2.5rem;
      color: var(--color-scrappi);
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(26, 26, 26, 0.1);
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
    }
    
    &__loader{
        transform: translate(-10%, -50%);
    }
    
  }
