.comments-drawer {
  height: 100%;
  &__header {
    margin: 0rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-actions {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
  &__heading-wrapper {
    display: flex;
    align-items: center;
    gap: 6px;
  }
  
  &__heading {
    margin: 0;
    color: var(--color-theme-2);
    font-size: var(--font-large);
    font-style: normal;
    font-weight: var(--font-weight-bold);
    line-height: 24px;
  }
  &__count {
    margin: 0;
    font-weight: var(--font-weight-medium);
    color: var(--color-theme-10);
    font-size: var(--font-semi-large);
    font-style: normal;
    line-height: 24px;
    opacity: 0.6;
  }

  


  &__close-button, &__sort-button {
    background-color: transparent;
    border: var(--border-close-icon-new-popup-header);
    padding: 10px;
    line-height: 0;
    border-radius: var(--border-radius-small);
    svg {
      height: 14px;
      width: 14px;
    }
    svg path {
      fill: var(--color-theme-icon-fill);
    }
  }
  &__sort {
    line-height: 0;
    &-button {
      svg {
        transform: rotateX(0deg);
        transition: transform 0.5s;
      }
      &:hover,&--active {
        border: 1px solid var(--color-scrappi);
        svg path {
          fill: var(--color-scrappi);
        }
      }
      
    }
    &-button--ascending {
      svg {
        transform: rotateX(180deg);
      }
      
    }
    &-button--descending {
      svg {
        transform: rotateX(0deg);
      }
      
    }
  }
  &__body {
    height: calc(100% - 68px);
  }
  
  .comments {
    padding: 8px;
    padding-bottom: 20px;
    height: calc(100% - 145px);
    overflow: auto;
  }
  &__body--read-only .comments {
    height: 100%;
  }
  &--limited-comments .comments {
    height: 100%;
  }
  .comment-input {
    border-top: 2px solid var(--color-scrappi);
    .tiptap--label-text-input__label {
      &:after {
        background: var(--color-theme-3);
      }
    }
  }
  /* &__scraps {
    overflow-y: overlay;
    padding: 1rem;
    padding-top: 0;
    height: calc(var(--screen-height) - 68px);
    .empty-page {
      width: 100%;
    }
  } */
}
