.ai {
  &__wrapper {
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    form {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  &__search {
    border: 1px solid #efefef;
    border-radius: 4px;
    width: 80% !important;
    min-width: 250px;
    max-width: 600px;
    height: 50px !important;
  }

  &__result {
    /*text-align: center;*/
    max-width: 800px;
  }

  &__summary {
    text-align: left;
    max-width: 600px;
    margin: 0 auto;

    &--sent {
      font-size: 14px;
      font-style: italic;
    }
  }

  &__loading {
    color: #dd7373;
    font-weight: bold;
  }
}