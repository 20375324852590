
.topic-navigation {
  position: fixed;
  left: 0;
  top: 95px;
  height: calc(var(--screen-height) - 95px);
  background-color: var(--color-sidebar-background);
  color: var(--color-sidebar-text);
  width: 270px;
  overflow-y: auto;
  font-size: 0.95rem;
  z-index: 1;
  transform: translateX(0);
  transition: 0.2s transform;

  &__multi-select {
    margin: 0.5rem 0.8rem;

    .select-search {
      border: 1px solid #d9d9d9;
      border-radius: 5px;
      height: 34px;
    }

    .rw-widget-container {
      border-radius: 5px;
    }
    .rw-multiselect-taglist {
      vertical-align: top;
    }
    .rw-multiselect-tag {
      border-radius: 5px;
      /* padding: 0.1rem 0.5rem; */
      padding-left: 0.3rem;
      /* background-color: lightslategray; */
      color: white;
    }
  }
  &__select-search {
    padding: 1rem;
  }
  &__view-mode {
    display: flex;
    flex-direction: column;
    button {
      text-align: left;
      background-color: transparent;
      border: none;
      padding: 1rem 1rem 1rem 1.5rem;
      font-size: var(--font-medium);
      &.selected {
        border-left: 3px solid var(--color-scrappi);
        /* cursor: none; */
        pointer-events: none;
        color: #fa5a37;
      }
      
    }
  }

  &__header {
    padding: 1rem 0;
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }

  &__selected-options {
    margin-top: 5px;
    padding: 0px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    
    li {
      font-size: 0.9rem;
      line-height: 1;
      position: relative;
      border-radius: 0;
      margin: 2px 4px;
      padding: 0.2rem 0.5rem;
      padding-right: 1.8rem;
      border: 1px solid;
      border-color: #f15927;
      color: var(--color-text-body);

      &:hover {
        opacity: 0.8;
      }

      span {
        position: absolute;
        right: 6px;
        top: 0;
        font-size: 0.8rem;
        line-height: 1.3rem;
        cursor: pointer;
        color: #909090;
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        padding-left: 5px;
      }
    }
  }

  @media screen and (max-width: 960px) {
    transform: translateX(-285px);
  }

  &.collapsed {
    /* display: none; */
    transform: translateX(-285px);

    @media screen and (max-width: 960px) {
      /* display: none; */
      transform: translateX(0);
      box-shadow: 0 8px 20px -3px rgba(0, 0, 0, 0.2);
      width: 100%;
    }
  }

  &__topics {
    padding: 0 15px;
    max-height: 155px;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    padding-bottom: 0;

    /* &.expanded {
      max-height: 100%;
    } */
  }

  &__show-more {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    background: linear-gradient(#ffffff56, #ffffff 50%);
    padding: 0.5rem 0;
    cursor: pointer;
    border-radius: 3rem;
    font-size: 0.8rem;
    color: #f15927;
    font-weight: bold;
    letter-spacing: 1px;

    &:hover {
      filter: brightness(0.98);
    }
  }

  &__heading {
    font-weight: 700;
    margin-left: 15px;
    margin-top: 0;
    margin-bottom: 0;
  }
  &__item {
    list-style: none;

    a {
      display: flex;
      text-decoration: none;
      color: #303030;
      padding: 5px 0;
    }

    &.active {
      a {
        background-color: #f15927;
        padding: 5px 10px;
        color: #ffffff;
        border-radius: 20px 0 0 20px;
        margin: 0 -15px 0 -10px;
      }
    }
  }

  &__count {
    font-size: 0.8rem;
    opacity: 0.6;
    float: right;
  }

  &__filter {
    display: flex;
    align-items: center;
    line-height: 1.6;
    padding: 0.2rem 0;

    &:hover {
      color: var(--color-main-navigation-text);
      filter: contrast(0.8);
    }

    label {
      padding-left: 5px;
      padding-right: 10px;
      width: 100%;
      cursor: pointer;
      line-height: 1.4;
      word-break: break-word;
    }
  }
  &__active-filters {
    h3 {
      font-size: 1.2rem;
      font-weight: 700;
      margin: 0;
      letter-spacing: 1px;
    }
    & > div:first-child {
      display: flex;
      justify-content: space-between;
      button {
        text-decoration: underline;
        cursor: pointer;
        background-color: transparent;
        border: none;
      }
    }

    & > div {
      h4 {
        text-transform: none;
      }
    }
    &-body {
      display: flex;
      flex-wrap: wrap;
      padding: 0.8rem 0;

      p {
        position: relative;
        margin: 2px 4px;
        padding: 5px 10px;
        padding-right: 40px;
        background-color: var(--color-card-base);
        border-radius: 100px;
        span {
          cursor: pointer;
          border: 1.5px solid black;
          border-radius: 100%;
          position: absolute;
          top: 5px;
          right: 5px;
          width: 23px;
          height: 23px;
        }
        span::before,
        span::after {
          width: 12px;
          height: 2px;
          background-color: black;
          display: inline-block;
          content: '';
          position: absolute;
          left: 0;
        }
        span::after {
          transform: translate(4px, 8.5px) rotate(-495deg);
        }
        span::before {
          transform: translate(4px, 8.5px) rotate(495deg);
        }
      }
    }
  }
}

.filter {
  &__search {
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    height: 34px;
    padding: 0 1rem;
    line-height: 62px;
    width: calc(100% - 2.4rem) !important;
    margin: 0.5rem 1.2rem 0;
    font-size: 1rem;
    transition: 0.3s;
  }
  &__search:focus,
  &__search:active {
    outline: none;
    border: 1px black solid !important;
  }
}

.AddTopicPopup {
  display: inline-block;
  position: fixed;
  top: 0;
  left: 0;
  background: #000000bd;
  height: var(--screen-height);
  width: 100vw;
}

.AddTopicPopupDialog {
  background: white;
  padding: 10px 50px;
  background: #ffffff;
  box-shadow: 0 4px 14px -4px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  margin: 1.2rem 0;
  cursor: pointer;
  overflow: hidden;
  transition: 0.3s ease-in-out;
  transition-property: box-shadow, transform;
  transform: translateY(0);
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: calc(100% - 1rem);
  margin: 0.5rem;
  position: absolute;
  padding-bottom: 50px;
  width: 40%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.secondary__header {
  top: 70px;
  display: flex;
  z-index: 3;
  position: fixed;
  background-color: var(--color-secondary-navigation-background);
  color: var(--color-secondary-navigation-text);
  backdrop-filter: blur(56px);
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(112, 112, 112, 0.1);
  box-shadow: 0 3px 6px -2px rgba(0, 0, 0, 0.15);
  transition: 0.3s;

  @media screen and (min-width: 760px) {
    height: 48px;
  }
  .batch-button{
    padding: 0.2rem 0.2rem 0;
    
  }
  .batch-button--active {
    color: var(--color-scrappi);
    border-color: var(--color-scrappi);
  }
  

  &.mobile-collapse {
    @media screen and (max-width: 759px) {
      display: block;

      .navigation__title {
        margin-bottom: 0.6rem;
        margin-left: 1rem;
      }
    }
  }

  &--inner {
    @media screen and (max-width: 759px) {
      display: block;
      position: relative;
      top: 0;
      height: auto;
    }
  }

  &--left,
  &--right {
    display: flex;
    align-items: center;
    padding: 0.8rem;
    border-bottom: var(--card-border);

    @media screen and (min-width: 760px) {
      border-bottom: none;
      padding: 0 0.8rem;
    }
  }

  button {
    margin-right: 0.5rem;
    max-height: max-content;
  }

  .navigation__title {
    font-size: 1rem;
    margin: 0;
    transition: 0.1s;

    @media screen and (min-width: 760) {
      font-size: 1.25rem;
    }
  }

  &.static-header {
    box-shadow: none;
    border-bottom: none;
    padding: 1.2rem;
    height: auto;

    .navigation__title {
      @media screen and (min-width: 760px) {
        font-size: 2rem;
      }
    }
  }

  .scrap-count {
    margin-right: 1rem;
    display: none;

    @media screen and (min-width: 960px) {
      display: inline-block;
    }
  }

  .filter-toggle {
    color: var(--color-font-primary);
    font-weight: 700;
    font-size: 1rem;
    padding: 0.3rem 0.93rem;
    display: inline-block;
    cursor: pointer;
    transition: 0.2s;
    &:hover {
      background-color: var(--color-hover-light);
      border-radius: 0.5rem;
    }

    @media screen and (max-width: 960px) {
      span {
        display: none;
      }

      i:before {
        transform: rotate(180deg);
      }

      &.back {
        i:before {
          transform: rotate(0);
        }
      }
    }
  }

  .filter-toggle + div {
    display: flex;
    align-items: center;
  }
}

/* differnt height in scrap details */
@media screen and (max-width: 759px) {
  .scrap-details {
    .secondary__header {
      height: 78px;
    }
  }
}

/*
APPLIED FILTERS
*/
.filter {
  &__applied {
    &-wrapper {
    }

    &-elements {
      position: relative;
      padding: 0.3em 0.8rem;
      padding-right: 2rem;
      background: #efefef;
      margin: 0.3rem;
       {
      }
      border-radius: 1.2rem;
      text-align: center;
      font-size: 0.8rem;
      display: inline-block;
    }

    &-remove {
      position: absolute;
      right: 0.5rem;
      top: 0;
      font-size: 1rem;
      width: 1rem;
      border-left: 1px solid #d6d6d6;
      cursor: pointer;
    }
  }
  &__global-options {
    display: flex;
    align-items: center;
    p {
      margin-right: 15px;
    }
    .dropdown {
      width: 160px;
      position: relative;
      margin-right: 0.5rem;

      &__icon {
        position: absolute;
        top: 8px;
        left: 8px;
        z-index: 5;
        pointer-events: none;
      }

      .rw-widget {
        .rw-widget-picker {
          height: 34px;
          display: block;
        }
      }

      .rw-input {
        opacity: 0;
      }

      .rw-select {
        display: none;
      }

      .rw-popup-container {
        width: 200px;
        left: inherit;
      }
    }
  }
  &__sort-by {
    display: flex;
    align-items: center;
    p {
      margin-right: 15px;
    }
    .dropdown {
      width: 85px;
      position: relative;
      margin-right: 0.5rem;

      &__icon {
        position: absolute;
        top: 8px;
        left: 8px;
        z-index: 5;
        pointer-events: none;
      }

      .rw-widget {
        .rw-widget-picker {
          height: 35px;
          display: block;
        }
      }

      .rw-input {
        opacity: 0;
      }

      .rw-select {
        display: none;
      }

      .rw-popup-container {
        width: 200px;
        left: inherit;
      }
    }
  }
}

.view-toggle {
  cursor: pointer;
  opacity: 0.9;
  margin-right: 0.4rem;
  padding: 0.2rem 0.4rem;
  border-radius: var(--border-radius);

  &.active {
    background-color: var(--color-background-dark);
    color: var(--color-white);
  }

  @media screen and (max-width: 750px) {
    display: none;
  }
}

/**
 * Main wrapper
 */
.select-search {
  width: 100%;
  position: relative;
  font-family: 'Inter', sans-serif;
  box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
}

/**
* Value wrapper
*/
.select-search__value {
  position: relative;
  z-index: 1;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  height: 34px;
}

.select-search__value::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
  width: 11px;
  height: 11px;
  display: none;
}

/**
* Input
*/
.select-search__input {
  display: block;
  height: 32px;
  width: 100%;
  padding: 0 40px 0 16px;
  /* background: #fff; */
  border: 1px solid transparent;
  /* box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15); */
  border-radius: 3px;
  outline: none;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 36px;
  -webkit-appearance: none;
}

.filter__search, .select-search__input{
  text-transform: capitalize;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.select-search__input:not([readonly]):focus {
  cursor: initial;
}

/**
* Options wrapper
*/
.select-search__select {
  background: var(--color-background);
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);

  ul {
    background: orange;
    padding: 0;
    margin: 0.5rem;

    li {
      background: pink;
    }
  }
}

/**
* Options
*/
.select-search__options {
  list-style: none;
}

/**
* Option row
*/
.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

/**
* Option
*/
.select-search__option,
.select-search__not-found {
  display: block;
  height: fit-content;
  width: 100%;
  padding: 10px 16px;
  background: #fff;
  border: none;
  outline: none;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  text-align: left;
  cursor: pointer;
}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-selected {
  /* background: #fa5a37; */
  color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  /* background: rgba(47, 204, 139, 0.1); */
  /* background: #efefef; */
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  /* background: #fa5a37; */
  color: #fff;
}

/**
* Group
*/
.select-search__group-header {
  font-size: 10px;
  text-transform: uppercase;
  /* background: #eee; */
  padding: 8px 16px;
}

/**
* States
*/
.select-search.is-disabled {
  opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

/**
* Modifiers
*/
.select-search--multiple {
  border-radius: 3px;
  overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple)
  .select-search__value::after {
  transform: rotate(45deg);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none;
}

.select-search--multiple .select-search__input {
  cursor: initial;
}

.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
  border-color: #fa5a37;
}

.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 34px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.select-search--multiple .select-search__select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}

.select-search__not-found {
  height: auto;
  padding: 16px;
  text-align: center;
  color: #888;
}

.story-edit__wrapper {
  .topic-navigation {
    @media screen and (max-width: 960px) {
      transform: translateX(-285px);
    }
  }
}

.rw-widget-container {
  background: transparent !important;
}

.select-search__select {
  button {
    background-color: var(--color-background-base);
  }
}