.chat {
  /* for the popup wrapping and stying */
  &__container {
    &-content {
      .new-popup__body {
        padding: 0;
      }
    }
  }

  &__bubble {
    position: absolute;
    bottom: 85px;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: var(--color-scrappi);
    color: white;
    z-index: 300;
    cursor: pointer;
    box-shadow: 10px 16px 20px 0 rgba(0, 0, 0, 0.5);

    @media screen and (max-width: 768px) {
      bottom: 160px;
    }

    svg {
      width: 20px;
      height: 20px;

      path {
        fill: var(--color-white);
      }
    }
  }

  &__thread {
    &-add {
      padding: 1rem 0.5rem;
      color: var(--color-scrappi);
      text-decoration: underline;
      font-size: 0.9rem;
      cursor: pointer;
    }
  }

  &__message {
    position: relative;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    font-size: 1rem;
}

  &__actions {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0.2rem 0.1rem;
  }

  &__copy {
    position: relative;
  }

  &__citation {
    color: var(--color-scrappi);

    &-reference {
      text-decoration: underline;
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    &-icon {
      margin-right: 5px;
      position: relative;
      top: 2px;

      svg {
        width: auto;
        height: 14px;
      }
    }
  }

  &__scrap {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    width: 100%;

    &-link {
      display: flex;
      gap: 5px;
      align-items: center;
      color: var(--color-link-color);
    }

    &-domain {
      line-height: 20px;
    }

    &-icon {
      margin: 0;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-title {
      font-weight: bold;
    }

    &-description {
      opacity: 0.75;
    }
  }

  &__wrapper {
    bottom: 25px;
    right: 40px;
    width: 100%;
    max-width: 850px;
    height: 70vh;
    max-height: 700px;
    overflow: hidden;

    @media screen and (max-width: 670px) {
      height: 100%;
      max-height: 100%;
      padding-bottom: 20px;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;

    h2 {
      font-size: 1rem;
    }
  }

  &__close {
    cursor: pointer;
    opacity: 0.6;
  }

  &__collection {
    &-message {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 60px;

      a {
        display: block;
        text-decoration: none;
        background-color: var(--color-scrappi);
        color: var(--color-white);
        max-width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

.cs-message__content {
  white-space: normal;
}

.cs-conversation-header {
  background-color: var(--color-card-new-snippet);
  color: var(--color-text-body);
  border: none !important;
}

.cs-main-container .cs-chat-container {
  flex-basis: 85%;
  background-color: var(--color-card-new-base-other);
}

.cs-main-container>.cs-sidebar.cs-sidebar--left {
  flex-basis: 25%;
  background-color: var(--color-card-new-snippet);
  color: var(--color-text-body);
  border-right: 1px solid var(--color-side-nav-filter-border);

  @media screen and (max-width: 670px) {
    flex-basis: 100% !important;
    width: 100% !important;
    max-width: 100%;

    .cs-conversation {
      padding: 0.5rem 1rem;
      font-size: 1rem;
    }
  }
}

.cs-conversation {
  font-size: 0.9rem;
  padding: 0.4rem 0.8rem;
}

div .cs-message-list__typing-indicator-container div {
  color: #6d4700;
}

div .cs-main-container,
div .cs-chat-container .cs-message-input {
  border: 0px;
}

.cs-button,
.cs-button--send {
  color: var(--color-scrappi);
}

div .cs-message--incoming .cs-message__content {
  /*background-color: var(--color-scrappi);*/
  background: transparent;
  color: var(--color-text-body);
}

.cs-conversation {
  &.active {
    .cs-conversation__name {
      color: var(--color-text-body);
    }
  }
}

.cs-conversation {
  color: var(--color-text-body);
  background: transparent;

  &:hover,
  &:active {
    background: transparent;
  }

  .cs-conversation__name {
    color: var(--color-gray-dark-100);
  }
}

div .cs-message--outgoing .cs-message__content {
  background-color: var(--color-checkbox-label-background);
  color: var(--color-scrappi);
}

.cs-conversation.cs-conversation--active {
  background-color: var(--color-checkbox-label-background);

  .cs-conversation__name {
    color: var(--color-scrappi);
  }
}

.cs-message-input--disabled .cs-message-input__content-editor-wrapper,
.cs-message-input__content-editor-wrapper {
  background-color: #fdf1e8;
  color: var(--color-black);

  .cs-message-input__content-editor {
    background-color: #fdf1e8;
  }
}

.cs-message-list .cs-typing-indicator {
  background-color: transparent;

  .cs-typing-indicator__text {
    color: var(--color-text-body);
  }
}

.cs-conversation.cs-conversation--active {
  color: var(--color-text-body);
}

.cs-typing-indicator__dot {
  background: var(--color-scrappi);
}

div .cs-typing-indicator__dot {
  /*background-color: #6d4700;*/
}

.cs-message-list,
.cs-chat-container {
  background: transparent;
}

.cs-chat-container .cs-input-toolbox {
  background: transparent;
}

.cs-chat-container {
  .cs-message-input {
    flex: 1;
    background: transparent;

    &__tools {
      width: 40px;
      justify-content: center;

      .cs-button {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.cs-message-list__scroll-wrapper>.cs-message {
  &.cs-message--incoming {
    max-width: 100%;
  }
}

.cs-message-input__content-editor-container {
  font-size: 1rem;
}

/* truncate the text field */
.cs-message-input__content-editor[data-placeholder]:empty:before {
  position: absolute;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.all-conversation-toggle {
  text-align: center;
  font-size: 0.75rem;
}