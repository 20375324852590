.theme-toggle {
  padding: 4px;
  &__title {
    font-weight: var(--font-weight-medium);
    font-size: var(--font-smaller);
    line-height: 20px;
    padding: 7px 7px;
    padding-left: 7px;
    display: inline-block;
    color: var(--color-font-navigation-title);
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__list-item {
    cursor: pointer;
    padding: 6px 6.5px;
    display: flex;

    gap: 6.5px;
    border-radius: var(--border-radius-small);
  }
  &__list-item--active,
  &__list-item:hover {
    background: var(--color-theme-active-background);
  }

  &__icon {
    path {
      fill: var(--color-theme-icon-fill);
    }
  }
  &__list-item--active &__icon,
  &__list-item:hover &__icon {
    path {
      fill: var(--color-scrappi);
    }
  }

  &__item-text {
    font-size: var(--font-smaller);
    font-weight: var(--font-weight-semi_bold);
    line-height: 20px;
    color: var(--color-font-navigation);
  }
  &__list-item--active &__item-text,
  &__list-item:hover &__item-text {
    color: var(--color-scrappi);
  }
}
