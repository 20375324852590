@import './style-helpers/normalize.css';
@import './style-helpers/variables.css';

hr {
  background-color: var(--color-very-light-pink);
}
embed:not(.youtube embed) {
  height: 100% !important;
}
input {
  line-height: unset !important;
  &::-ms-reveal {
    display: none;
  }
}

/* Headings */
.page {
  &__with-header {
    padding-top: 0px;
  }

  &__heading {
    font-size: var(--font-heading2);
    margin: 0.1rem 0 1rem;
    font-weight: var(--font-weight-bold);
  }

  &__sub-heading {
    font-size: var(--font-normal);
    color: var(--color-brown-grey);
    margin: 0;
    font-weight: var(--font-weight-bold);
  }
}

h5,
.h5 {
  font-size: 1.25rem;
}

/* SHARED CARD STYLES */
.card-block {
  &__item {
    cursor: pointer;
    position: relative;
    width: 100%;
    /* min-height: 300px; */
    margin: 1rem;
    align-items: center;
    border: var(--card-border);
    border-radius: var(--border-radius);
    box-shadow: var(--shadow);
    padding: var(--card-padding);
    text-decoration: none;
    color: inherit;
    transition: 0.3s;

    &.add {
      &:hover {
        background-color: var(--color-very-light-pink-50);
      }
    }

    @media screen and (min-width: 960px) {
      width: calc(50% - 2rem);
    }

    @media screen and (min-width: 1200px) {
      width: calc(33.33% - 2rem);
    }

    &.add {
      border: var(--card-border-dashed);
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;

      div {
        text-align: center;
        > .label {
          display: block;
        }
      }

      .action-item {
        margin: 1rem auto 0;
      }
    }
  }
  &__story {
    cursor: auto;
  }

  &__title {
    margin: 0.75rem 0 0;
    color: var(--color-font-primary);
    text-transform: capitalize;
    font-size: var(--font-large);
    font-weight: var(--font-weight-bold);
  }

  &__count {
    padding: 0.1rem 0.6rem;
    position: absolute;
    background-color: var(--color-background-translucent);
    top: 20px;
    left: 20px;
    border-radius: 20px;
    font-size: var(--font-normal);
    font-weight: var(--font-weight-bold);
  }
}

/* BUTTONS */
.button {
  background-color: var(--color-blue);
  border: 1px solid;
  border-color: transparent;
  box-shadow: none;
  padding: 0.5rem 1.2rem;
  color: white;
  border-radius: var(--border-radius-small);
  text-decoration: none;
  max-height: 38px;

  &.btn-action {
    padding: 8px 16px;
    border-radius: 4px;
    font-weight: var(--font-weight-medium);
    font-size: var(--font-smaller);
    line-height: 20px;
  }

  &.active {
    box-shadow: 0 0 0 3px var(--color-orange);
  }

  &.btn-action {
    padding: 8px 16px;
    border-radius: 4px;
    font-weight: var(--font-weight-medium);
    font-size: var(--font-smaller);
    line-height: 20px;
  }

  &__primary {
    background-color: var(--color-scrappi);
    font-weight: var(--font-weight-medium);
    &.btn-action {
      display: flex;
      justify-content: center;
      gap: 4px;
      align-items: center;
      &:disabled {
        background: #969696;
        opacity: 1;
      }
      svg path {
        color: var(--color-white);
      }
    }

    &:hover {
      background-color: var(--color-scrappi--hover);
    }
  }

  &:disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  &__block {
    display: block;
    width: 100%;
    text-align: center;

    &:hover {
      background-color: var(--color-blue--hover);
    }
  }

  &__secondary {
    background-color: #3d3d3d;
  }

  &__outline {
    color: var(--color-theme-2);
    border-radius: var(--border-radius-medium);
    border: 1px solid var(--color-theme-11);
    background: var(--color-theme-9);
    &.btn-action {
      display: flex;
      align-items: center;
      color: var(--color-theme-2);
      border-radius: var(--border-radius-medium);
      border: 1px solid var(--color-theme-11);
      background: var(--color-theme-9);
    }
    &.focused {
      color: var(--color-scrappi);
    }
  }
  &__underlined {
    background-color: transparent;
    text-decoration: underline;
    color: var(--color-dark);
  }

  &__round {
    border-radius: 30px;
  }

  &__link {
    color: var(--color-font-primary);
    background-color: transparent;
    border-color: transparent;
    text-decoration: underline;
    padding: 0.5rem 0.8rem;
  }

  &__delete {
    position: absolute;
    right: 20px;
    top: 20px;
    background: #fff;
    border: 1px solid rgba(106, 110, 115, 0.4);
    padding: 7px 8px 3px;
    border-radius: 20px;
    opacity: 0;
  }
  &__time {
    position: absolute;
    right: 20px;
    top: 9.5rem;
    background: #fff;
    border: 1px solid rgba(106, 110, 115, 0.4);
    padding: 3px 8px 3px;
    border-radius: 20px;
    font-size: smaller;
    font-weight: var(--font-weight-bold);
  }
  &__state {
    position: absolute;
    left: 20px;
    top: 9rem;
    background: rgba(255, 255, 255, 0.9);
    border: 1px solid rgba(106, 110, 115, 0.4);
    padding: 7px 8px 3px;
    border-radius: 20px;
    span {
      max-width: 0;
      -webkit-transition: max-width 1s;
      transition: max-width 1s;
      display: inline-block;
      vertical-align: top;
      white-space: nowrap;
      overflow: hidden;
      padding-top: 1px;
    }
  }
  &-back-to-results {
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--color-font-navigation-create-button) !important;
    font-weight: var(--font-weight-medium) !important;
    max-height: unset !important;
    background: var(--color-theme-9) !important;
    padding: 10px 16px !important;
    border-radius: 4px !important;
    border: none !important;
    margin-bottom: 16px;
    @media screen and (max-width: 450px) {
      width: 100%;
      justify-content: center;
    }
    svg path {
      fill: var(--color-font-navigation-create-button) !important;
    }
  }
}

/* Modal styles */

.popup-content {
  width: auto;
  /* width: 90%;
  max-width: 500px; */
  /* width: auto; */
  padding: 0;
}

.reader {
  &__url {
    color: var(--color-scrappi);
    margin-bottom: 0rem;
    width: 70%;
    text-overflow: ellipsis;
    display: inline-block;
    font-size: 0.8rem;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: text-top;
  }

  &__source-wrapper {
    position: relative;
    height: 30px;
    margin-bottom: 2.5rem;
    display: flex;
    align-items: center;
  }
}
button {
  cursor: pointer;
}

.rw-multiselect-taglist {
  display: block;
}

.share-link {
  pointer-events: none;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  display: flex;
  /* border: ; */
  &__input {
    border: none !important;
  }
  &__input:focus {
    outline: none;
  }
  &__copy-button {
    border: none;
    background-color: transparent;
    pointer-events: all;
  }
}

.copied {
  color: var(--color-green);
  margin-top: 0.5rem;
}

.reader__loading {
  max-width: 580px;
  margin: 2rem auto;

  .collections__item {
    width: 100% !important;
  }
}

.loading-message {
  display: flex;
  font-size: 0.8rem;
  opacity: 0.6;

  span {
    margin-left: 0.5rem;
  }
}

.form {
  margin-block-end: 0 !important;
}

.ql-container {
  display: flex;
}

.pointerAll-overlay {
  pointer-events: all !important;
}
.crop-image {
  width: 100%;
}

.rw-multiselect-taglist > li > span {
  overflow-wrap: anywhere !important;
}

.mt-1 {
  margin-top: 10px;
}

.instructions-content {
  width: auto;
  max-width: 80% !important;
  background: none;
  border: none;

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
}
.panel-details__upload__my-overlay {
  z-index: 7;
  margin-top: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.pl-0 {
  padding-left: 0 !important;
}
.clear-fix {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

.danger {
  display: inline-block;
  margin-top: 0.5rem;
  color: red;
}
.d-flex {
  display: flex;
}
.anchor-link {
  text-decoration: underline;
  cursor: pointer;
}
.ReactCrop__image {
  max-height: 90vh;
  max-width: 90vw;
  object-fit: contain;
}
/* FOR LOGO */
.mobile-logo {
  @media screen and (min-width: 451px) {
    display: none;
  }
}
.desktop-logo {
  @media screen and (max-width: 450px) {
    display: none;
  }
}
body > iframe {
  pointer-events: none !important;
}
audio {
  color-scheme: light;
}

.invisible-recaptcha {
  visibility: hidden;
}

.createAt__icon {
  width: 12px;
  height: 12px;
}

.profile-input{
  min-height: 42px !important;
}

/***  Hide Up & Down Arrow Buttons In Firefox Input  ***/
input[type=number] {
  -moz-appearance:textfield;
}
/***  Hide Up & Down Arrow Buttons In Firefox Input  ***/
input[type='file'] {
  display: none;
}

.image-carousel-popup {
  &-content.popup-content {
    .images-group {
      &__carousel {
        height: calc(var(--screen-height) - 192px);
        @media screen and (max-width: 670px) {
          height: 100%;
        }
      }
      
    }
    .new-popup {
      &__body {
        @media screen and (max-width: 670px) {
          max-height: unset;
          height: calc(var(--screen-height) - 70px) !important;
          margin-bottom: 0px;
        }
      }
    }
  }
}

.LinesEllipsis-ellipsis button {
  padding: 0;
  background: transparent;
  line-height: 18px;
  border: none;
  & > div {
    display: inline-block;
    background-color: var(--color-scrappi);
    width: 4px;
    height: 4px;
    border-radius: 300px;
    margin-left: 4px;
    transform: translateY(1px);
  }
  & > div:first-child {
    margin-left: 0;
  }
}
.LinesEllipsis .mention {
  color: var(--color-link-text);
  font-family: Inter;
  font-weight: var(--font-weight-medium);
  line-height: 18px;
  text-decoration: none;
}

mark {
  background-color: var(--color-highlight-background);
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}




.warningPopup{
  &__message{
    text-align: center;
    margin: 4rem 0;
    color: var(--color-theme-2);
    font-size: var(--font-normal);
    font-style: normal;
    font-weight: var(--font-weight-medium);
    line-height: 22px;
  }
}



.delete-scrap{
  &__message{
    text-align: center;
    margin: 4rem 0;
    color: var(--color-theme-2);
    font-size: var(--font-normal);
    font-style: normal;
    font-weight: var(--font-weight-medium);
    line-height: 22px;
  }

  &__loading-overlay {
    position: fixed;
    z-index: 9999999;
    height: 100%;
    width: 100%;
    font-size: 2.5rem;
    color: var(--color-scrappi);
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(26, 26, 26, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }
  
}

.App > .empty-page {
  margin: 1rem;
}

/* onboarding */
.react-joyride__overlay {
  z-index: 100 !important;
}

.__floater.__floater__open {
  z-index: 101 !important;
}



/*The following items are used for the /discover page in the MyFeedContainer */
.logo {
  top: 5px; 
  left: 5px; 
  z-index: 1000; 
  background-color: black;
}

.tabs-container {
  left: 0;
  right: 0;
  z-index: 999; 
  background-color: black; 
  border-top: 1px solid var(--color-scrappi);
}

.feed-container {
  margin-top: 5px; 
  background-color: black; 
}

.react-tabs__tab {
  color: white;
  padding: 3px 20px 3px 20px !important;
  border: none !important;
  cursor: pointer;
  border-bottom: none !important;
  bottom: 0px !important;
  font-size: 14px !important;
}

.react-tabs__tab-list {  
  border-bottom: 0px !important;
  margin: 0 0 0px !important;
  padding: 0;
}

.react-tabs__tab--selected {
  color: white !important; 
  background-color: var(--color-scrappi) !important;
  border-radius: 0 0 0 0 !important;
  border: none !important;
}

.react-tabs__tab:hover {
  background-color: var(--color-scrappi);
}

.react-tabs__tab-panel {
  padding: 0 !important;
  margin: 0 !important;
}

.react-tabs__tab-panel > * {
  padding: 0 !important;
  margin: 0 !important;
}

rssapp-magazine .rssapp-grid {
  padding: 0 !important;
  margin: 0 !important;
}
/****************************************************/



