.error {
  &-wrapper {
    padding: 2rem;
    align-items: center;
    min-height: 500px;
    width: 100%;
    max-width: 1000px;
    &__error-message{
      display: flex;
      flex-direction: column;
      &__link{
        color:var(--color-link-color)
      }
    }
  }
  &-message {
    font-weight: var(--font-weight-medium);
    color: var(--color-error);
    font-size: var(--font-smaller);
    margin: 0;
    border: 1px solid var(--color-error);
    padding: 0.6rem 0.3rem;
    text-align: center;
    border-radius: var(--border-radius-small);
    &--filled {
      border: solid 1px rgb(221, 115, 115);
      color: white;
      background-color: rgb(221, 115, 115);
    }
  }

  &__heading {
    font-size: 10rem;
    margin-bottom: 0.2rem;
    opacity: 0.8;

    &--small {
      font-size: 3rem;
      margin-bottom: .3rem;
    }
  }
  &__content {
    font-size: 1rem;
    
    &--mid {
      margin-top: 0.4rem;
      font-size: 1.6rem;
      opacity: 0.7;
    }  
  }

  &__image {
    &-wrapper {
      flex: 1;
      text-align: center;
    }

    max-width: 375px;
    width: 100%;
    height: auto;

    &--small {
      max-width: 200px;
    }
  }
  
}