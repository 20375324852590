.loading {
  &__video {
    position: relative;

    .react-loading-skeleton.card-base {
      margin: 0;
    }
  }

  &__icon {
    opacity: 0.3;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* For the individual collection view */
.collection-creation > div > span,
.collections__list.collections__list-loading {
  grid-template-columns: auto auto auto;
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin: 0 auto;
}

.public-collection.public-collection__loading {
  padding-top: 10rem;
  &> span {
    grid-template-columns: auto auto auto auto;
    display: grid;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    margin: 0 auto;
  }
}
