:root {
  --screen-height: 100vh;
  color-scheme: light;
  /* COLORS */
  --color-light-bluish-gray: #AFBCC0;
  --color-highlight-background: #FFC078;
  --color-white: #ffffff;
  --color-white-100: #e8eced;
  --color-white-200: #f8fafc;
  --color-white-300: #FFFFFF;
  --color-white-400: #F4F5F6;
  --color-white-500: #F5F5F5;
  --color-white-600: #EAEAEA;
  --color-main-background-light: #ffffff;
  --color-main-background-dark: #000000;
  --color-background-light: #d9d9d9;
  --color-background-dark: #282828;
  --color-background-translucent: rgba(255, 255, 255, 0.88);
  --color-background-success: #d4edda;
  --color-text-light: #ffffff;
  --color-text-light-200: #F8FAFC;
  --color-text-dark: #3c3c3c;
  --color-text-dark-100: #1C2F3A;
  --color-text-dark-200: #3a3a3a;
  --color-light-grey: #cccccc;
  --color-light-grey-100: #DDDDDD;
  --color-black: #000000;
  --color-black-100: #464646;
  --color-black-200: #474747;
  --color-black-300: #313131;
  --color-black-400: #191919;
  --color-sidebar-light: #f1f1f1;
  --color-sidebar-dark: var(--color-black-300);
  --color-main-navigation-background-light: #f8f8f8;
  --color-main-navigation-background-dark: #1c1c1c;
  --color-creame: #fdf1e8;

  /* Solid Colors */
  --color-scrappi: #eb6f19;
  --color-scrappi--hover: #de640f;
  --color-green: #28a745;
  --color-yellow: #ffca07;
  --color-yellow-100: #FFC224;
  --color-yellow-light: #FFFAEC;
  --color-dark-yellowish-gray: #6C6B68;
  --color-orange: #ff9c00;
  --color-orange-100: #ffcaa6;
  --color-dark: #343a40;
  --color-dark-blue: #27495e;
  --color-sky-blue: #C8D7E2;
  --color-sky-blue-100: #DAE8F1;
  --color-sky-blue-200: #C1D7E4;
  --color-midnight-teal: #36586C;
  --color-midnight-teal-100: #1a404c;
  --color-midnight-teal-200: #274a5e;
  --color-brown-grey: #979797;
  --color-gray-light: #ced4da;
  --color-gray-dark: #696969;
  --color-gray-dark-100: #616161;
  --color-gray-dark-200: #626262;
  --color-gray-dark-300: #575757;

  --color-very-light-pink: #d9d9d9;
  --color-very-light-pink-50: rgba(189, 189, 189, 0.5);
  --color-light: #f8f9fa;
  --color-success: #28a745;
  --color-success-dark: #155724;
  --color-plus-icon: #d46417;
  --color-grey: #969696;
  --color-grey-100: #AEAEAE;
  --color-grey-200: #B8B8B8;
  --color-grey-300: #9f9f9f;
  --color-grey-400: #e8e8e8;




  /* status colors */
  --color-warning: #f6c004;
  --color-link-color: #1d9bf0;
  --color-link-secondary: #1085c7;
  --color-hover-light: #efefef;
  --text-active: var(--color-black-400);
  --color-gray-background: #f0f0f0;

  --dropdown-hover-active: #ffe0a2;

  /* font weight */
  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-semi_bold: 600;
  --font-weight-bold: 700;
  --font-weight-boldest: 900;

  --color-collection-desc: #495057;
  --list-group-border-on: rgba(0, 0, 0, 0.12);

  /* Icon Colors */
  --icon-light: #6c757d;

  /* Responsive breakpoints */
  --screen-small: 960px;
  --screen-medium: 1080px;
  --screen-large: 1200px;
  --screen-x-large: 1440px;

  /* UI styles */
  --shadow: 0 2px 4px 0 rgba(226, 226, 226, 0.5);
  --border-radius: 8px;
  --border-radius-small: 4px;
  --border-radius-medium:6px;
  --border-radius-large: 10px;

  /* - cards */
  --card-border: 1px solid var(--color-very-light-pink-50);
  --card-border-dashed: 1px dashed var(--color-very-light-pink-50);
  --card-border-success: solid 1px #c3e6cb;
  --card-padding: 0.75rem;

  /* - container */
  --container-width--max: var(--screen-large);

  /* Button colors */
  --button-overlay: rgba(255, 255, 255, 0.88);
  --button-white: #ffffff;
  --button-primary-disabled: #c9c9c9;
  --color-blue: #0e8bbd;
  --color-blue--hover: #0d7aa6;

  /* FONT STYLES */
  --font-small: 0.75rem;
  --font-smaller: 0.875rem;

  --font-normal: 1rem;
  --font-semi-large: 1.125rem;
  --font-large: 1.25rem;
  --font-heading1: 1.5rem;
  --font-heading2: 2rem;
  /* Font Colors */
  --color-font-primary: #212529;
  --color-font-secondary: #6c757d;
  --color-font-light: #464a4e;
  --color-font-lighter: #868e96;
  --color-font-lightest: #bababa;
  --color-font-link: #007bff;
  --color-font-light-footer: #4b4b4b;
  --color-font-meta: var(--color-grey);

  /* pills/badge */
  --badge-collection-resting: #f7f7f7;

  /*
   * Theme preference for dark and light mode settings
  */

  /* Base theme variables */
  --color-background-base: #ffffff;
  --color-background-main: var(--color-main-background-light);
  --color-error: #ff0000;
  --color-text-body: var(--color-text-dark);
  --color-text-body-inverse: var(--color-text-light);
  --color-link-text: var(--color-link-color);
  --color-background: var(--color-background-light);
  --color-sidebar-background: var(--color-sidebar-light);
  --color-sidebar-text: var(--color-text-dark);
  --color-main-navigation-background: var(
    --color-main-navigation-background-light
  );
  --color-main-navigation-text: var(--color-text-dark);

  /* Hover effect */
  --color-background-hover: var(--color-white-600);

  /* Theme based colors */
  --color-theme-1: var(--color-midnight-teal-100);
  --color-theme-2: var(--color-midnight-teal-200);
  --color-theme-3: var(--color-white);
  --color-theme-4: var(--color-gray-background);
  --color-theme-5: var(--color-black);
  --color-theme-6: var(--color-font-light-footer);
  --color-theme-7: var(--color-light-grey-100);
  --color-theme-8: var(--color-grey);
  --color-theme-9: var(--color-white-400);
  --color-theme-10: #84929a;
  --color-theme-11: #dde2e4;
  --color-theme-12: var(--color-gray-background);
  --color-theme-13: var(--color-midnight-teal-200);
  --color-theme-14: var(--color-midnight-teal-200);
  --color-theme-15: var(--color-light-bluish-gray);

  /* secondary navigation */
  --color-secondary-navigation-background: rgba(255, 255, 255, 0.7);
  --color-secondary-navigation-text: --color-text-dark;

  /* Borders */
  --primary-border: 1px solid #bababa;

  /* side navigation */
  --color-side-nav-filter-border: var(--color-light-grey-100);
  --color-shadow-side-nav-filter: #ffffff;
  --color-font-side-nav-filter-label: var(--color-text-dark);
  --color-border-side-nav-dropdown-search: var(--color-font-lightest);
  --color-background-side-nav-dropdown: #e0e0e0;
  --color-border-side-nav-dropdown: #bababa;
  --color-font-side-nav-dropdown-highlight: var(--color-creame);
  --color-font-side-nav-view-more: var(--color-font-light-footer);
  --color-font-side-nav-create: #585858;
  --color-font-side-nav-policy-text: var(--color-font-light-footer);
  --color-font-side-nav-item-label: var(--color-text-dark);
  --color-font-side-nav-item-label-active: var(--color-text-dark);
  --color-border-menu-items: #bababa;

  /* FILTERS LIST */
  --color-clear-text: var(--color-text-dark);
  --color-background-filter-list: #cccccc;
  --color-background-filter-list-border: 1px solid #eeeeee;
  --color-background-filter-list-box-shadow: inset 0px 0px 3px 1px
      rgba(0, 0, 0, 0.25),
    inset 0px 0px 0px 2px #bababa;

  /* card */
  --color-card-base: var(--color-sky-blue-100);
  --color-card-base-other: var(--color-gray-background);
  --color-card-meta: #bed6e5;
  --color-card-meta-other: var(--color-light-grey-100);
  --color-card-text: var(--color-text-dark);
  --color-card-snippet: #e0e0e0;
  --color-card-meta-text: var(--color-midnight-teal-200);
  --color-font-card-link-preview: var(--color-text-dark);
  --border-card-base: 1px solid #bababa;
  --border-card-blue: 1px solid #94bad1;
  --border-card-outer: 1px solid var(--color-white-200);
  --border-card-outer--color: #f1f6f9;
  --border-card-outer--color-blue: #f1f6f9;
  --border-card-outer--color-other: #e0e0e0;
  --border-shadow-card: 1px solid #f1f6f9;
  --border-shadow-card-other: 1px solid #f7f7f7;
  --card-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  --color-card-link-border: #ffffff;
  --color-card-link-border-inner: #bababa;
  --border-card-content-inner: 1px solid #94bad1;
  --border-card-content-outer: 1px solid var(--color-white-200);
  --color-annotation-text: #161616;
  --color-card-font-user-name: #84929a;
  --color-comment-icon: var(--color-grey);
  --background-card-notes: var(--border-card-outer--color-blue);
  --color-card-new-snippet: var(--color-gray-background);
  --color-card-new-meta-other: var(--color-gray-background);
  --color-card-new-base-other: #ffffff;
  --color-card-new-meta: var(--color-sky-blue);
  --color-card-new-snippet:#F0F0F0;
  --color-card-new-meta-other:#F0F0F0;
  --color-card-new-base-other:#FFFFFF;
  --color-card-icon-hover:rgb(242, 253, 251);
  --color-background-card-highlights: var(--color-yellow-light);
  --background-color-card-view-all-button: var(--color-creame);
  --border-card-footer: 1px solid var(--color-light-bluish-gray);

  /* Image Carousel */
  --color-background-carousel-button: var(--color-white);

  /* checkbox colors */
  --color-checkbox-background: var(--color-white);
  --color-checkbox-border: var(--border-card-base);
  --color-checkbox-label-background: var(--color-creame);

  /* Theme Toggler colors */
  --color-font-theme-title: var(--color-font-light-footer);
  --color-theme-icon-fill: var(--color-font-light-footer);
  --color-theme-active-background: var(--color-creame);

  /* Main Navigation */
  --color-background-navigation-dropdown: var(--color-white);
  --color-font-navigation: #838383;
  --color-font-navigation-title: var(--color-font-light-footer);
  --filter-navigation-background-menu: drop-shadow(
    0px 2px 4px rgba(0, 0, 0, 0.25)
  );
  --color-font-navigation-username: var(--color-text-dark);
  --color-background-navigation-create-button: var(--color-creame);
  --color-font-navigation-create-button: #d46417;
  --border-font-navigation-create-button: 1px solid #f5b78c;

  /* Global Search */
  --border-global-search-bar-suggestions: 1px solid #bababa;
  --background-global-search-bar-input: var(--color-white);
  --background-global-search-submit-button: #f1f1f1;
  --color-global-scrapper-bio: var(--color-text-dark);
  --border-view-profile-button: 1px solid #f5b78c;
  --color-font-header-global-search: var(--color-font-light-footer);
  --color-font-global-search-item-count: var(--color-text-dark);
  --color-font-global-search-header-nav: var(--color-font-light-footer);
  --color-text-global-search: var(--color-text-dark);


  /* Collection Header */
  --border-outer-collection-card-footer: 1px solid var(--color-white-600);
  --color-border-inner-collection-card-footer: transparent;
  --border-top-collection-header: 1px solid #ffffff;
  --box-shadow-collection-header: 0px 2px 4px rgba(0, 0, 0, 0.25);
  --border-bottom-collection-header-head: 1px solid #c4c4c4;
  --border-collection-header-image: 1px solid #bababa;
  --color-font-edited-date-collection-header: #7e7e7e;
  --border-top-collection-header-footer: 1px solid #f7f7f7;
  --color-background-collection-header-footer: var(--color-light-grey-100);
  --border-collection-header-collaborator: 2px solid var(--color-light-grey-100);
  --color-font-collection-header-icons: var(--color-text-dark);
  --color-font-collection-header-owner-name: var(--color-black-100);
  --color-font-collection-header-owner-username: #888888;
  --color-collection-add-image: var(--color-white-400);
  --color-collection-header:var(--color-sky-blue);
  --color-collection-header-inverse: var(--color-midnight-teal);
  --color-collection-status-icon: var(--color-midnight-teal-200);
  --color-collection-text: var(--color-sky-blue);
  --color-collection-usename-text: var(--color-text-light-200);
  --color-collection-desc-text: var(--color-text-dark);
  --border-collection-collaborator-count: 2px solid var(--color-white);

  /* Loading Scrap Card */
  --color-background-loading-scrap-card: var(--color-gray-background);
  --border-top-loading-scrap-card: 1px solid #ffffff;
  --box-shadow-loading-scrap-card: 0px 2px 4px rgba(0, 0, 0, 0.25);
  --border-outer-header-loading-scrap-card: 1px solid #fff;
  --border-inner-header-loading-scrap-card: 1px solid #bababa;
  --color-background-header-image-loading-scrap-card: #fff;
  --color-fill-image-loading-scrap-card: #bdbdbd;
  --color-background-header-content-loading-scrap-card: #e6e6e6;
  --border-top-outer-footer-loading-scrap-card: solid 1px #c4c4c4;
  --border-top-inner-footer-loading-scrap-card: solid 1px #f7f7f7;
  --color-background-footer-loading-scrap-card: var(--color-light-grey-100);
  --color-header-line-loading-scrap-card: #c1c1c1;
  --color-body-line-loading-scrap-card: #cfcfcf;
  --color-footer-line-loading-scrap-card: #bababa;
  --animate-line-loading-scrap-card: linear-gradient(
      127.97deg,
      rgba(243, 243, 243, 0) 26.3%,
      rgba(243, 243, 243, 0.2) 54.62%,
      rgba(243, 243, 243, 0) 80.68%
    ),
    #bababa;
  /* Scrap Controls */
  --color-font-scrap-count-scrap-controls: var(--color-font-light-footer);
  --color-background-dropdown-scrap-controls: var(--color-white);
  --filter-dropdown-scrap-controls: drop-shadow(
    0px 2px 4px rgba(0, 0, 0, 0.25)
  );
  --color-font-button-scrap-controls: var(--color-text-dark);
  --color-font-dropdown-label-scrap-controls: var(--color-font-light-footer);
  --color-background-dropdown-item-active-scrap-controls: var(--color-creame);
  --color-font-dropdown-item-scrap-controls: #838383;
  --color-icon-dropdown-scrap-controls: var(--color-font-light-footer);

  /* Card Settings */
  --border-list-card-settings: 1px solid white;
  --box-shadow-list-card-settings: 0px 2px 4px rgba(0, 0, 0, 0.25);
  --color-background-list-card-settings: var(--color-white);
  --color-font-card-settings: #838383;
  --color-font-hover-card-settings: var(--color-white);
  --color-background-hove-card-settings: var(--color-scrappi);
  --color-icon-card-settings: #72a7c5;
  --color-icon-card-settings--other: var(--color-gray-dark);
  --color-icon-card-settings-active: var(--color-text-dark);
  --color-time-card-settings: var(--color-font-light-footer);
  --color-icon-meta-card-settings-other: var(--color-grey);

  /* pills badge */
  --color-font--pill-label: #838383;
  --color-background-pill-hover: transparent;

  /* BATCH EDIT */
  --close-icon-fill-color: var(--color-grey);
  --color-action-message: #585858;

  /* New Popup */
  --border-new-popup: none;
  --color-border-line-new-popup: #dde2e4;
  --background-color-new-popup-header-icon: var(--color-white-100);

  --filter-shadow-new-popup: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
  --background-color-new-popup-header: #fdfdfd;
  --border-close-icon-new-popup-header: 1px solid #e0e0e0;
  --background-color-new-popup-body: var(--color-white);
  --border-top-new-popup-body: 1px solid var(--color-light-grey-100);
  --background-color-new-popup-footer: #fdfdfd;
  --background--copylink-input-new-popup: var(--color-gray-background);
  --copylink-button-new-popup: var(--color-creame);
  --copylink-button-border-new-popup: #f5b78c;
  --copylink-button-text-new-popup: #d46417;
  --switchbutton-background-new-popup: #cccccc;
  --background-color-new-popup-tiptap: #e0e0e0;
  --color-new-popup-label: var(--color-text-dark);
  --border-new-popup-input: 1px solid #bababa;
  --color-add-tags-title: var(--color-font-light-footer);

  /* ICON COLORS */
  --color-icon-tags: var(--color-grey);

  /* TEXT INPUT */
  --input-border-color: #bababa;
  --input-tiptap-icon: var(--color-black);
  --color-tiptap-background: #e0e0e0;
  --input-otp-border-color: #bababa;

  --color-invite-scrapper-email: #bababa;
  --color-invite-scrapper-message: var(--color-grey);

  /* Collection Collaboration Popup */
  --color-font-label-collaborator: var(--color-text-dark);
  --background-color-collaborator-input: var(--color-white);
  --color-font-collaborator-input-placeholder: var(--color-font-lightest);
  --color-font-collaborator-input: var(--color-text-dark);
  --border-collaborator-input: 1px solid var(--color-font-lightest);
  --border-bottom-collaborator: 1px solid var(--color-light-grey-100);
  --border-top-collaborator: 1px solid var(--color-white);
  --bottom-collaborator-background: #f1f1f1;
  /* New Popup Input Focus */
  --border-new-popup-input-focus: 1px solid #bababa;

  /* Scrap Images Popup */
  --border-uploader-container-scrap-images: 1px solid var(--color-font-lightest);
  --box-shadow-uploader-container-scrap-images: inset 0px 0px 0px 2px #bababa;
  --color-font-uploader-button-scrap-images: var(--color-font-light-footer);
  --color-font-add-button-scrap-images: var(--color-font-light-footer);
  --border-uploader-button-scrap-images: 1px solid var(--color-font-meta);
  --color-font-uploader-support-text-scrap-images: var(--color-font-meta);
  --border-scrap-saved-edit-button: 1px solid var(--color-light-grey-100);
  --color-font-scrap-saved-edit-button: var(--color-font-light-footer);

  /* PROFILE PAGE */
  --border-top-profile-card: none;
  --border-bottom-profile-card: 1px solid var(--color-light-grey-100);
  --box-shadow-profile-card: 0px 2px 4px rgb(0 0 0 / 25%);
  --profile-page-background: #cacaca;
  --profile-page-sidebar-border: 1px solid var(--color-light-grey-100);
  --profile-page-form-input-background: #ffffff;
  --profile-page-form-input-username: var(--color-light-grey-100);
  --profile-page-password-cross-svg-fill: var(--color-grey);
  --profile-page-border: 1px solid #bababa;

  /* Bulk Edit & Select */
  --bulk-input-text-color: var(--color-text-dark);

  /* Share Collection */
  --share-collection-switch-toggle-color: var(--color-gray-background);
  /* COLLECTION ORGANIZE MODE */
  --color-box-shadow-collection-organize: #bababa;
  --color-background-collection-organize: #cccccc;

  /* Tiptap */
  --color-font-tiptap-button: var(--color-black);
  --background-color-tiptap: #e0e0e0;
  --color-font-tiptap-placeholder: var(--color-grey);
  --background-color-tiptap-button-active: #e0e0e0;
  /* Add Images Popup */
  --color-background-add-images: #f1f1f1;

  /* Collection Settings */
  --background-collection-settings-name-field: #e0e0e0;
  --background-color-collection-settings-view: var(--color-white);
  --border-collection-settings-view-dropdown: 1px solid
    var(--color-font-lightest);
  --color-font-collection-settings-view-dropdown-current: #838383;
  --background-color-collection-settings-view-dropdown: var(--color-white);
  --background-color-collection-settings-view-dropdown-item-hover: var(
    --color-light
  );
  --color-font-collection-settings-delete-button: var(--color-text-dark);
  --color-icon-collection-settings-view-dropdown: var(--color-font-meta);

  /* Image Cropper */
  --color-icon-image-cropper-control: var(--color-black);
  --color-font-image-cropper-cancel: var(--color-grey);

  /* TabSwitch Background */
  --color-tab-background: var(--color-white);

  /* Auth Wrapper Background */
  --color-auth-background: var(--color-white);
  --color-auth-divider: var(--color-light-grey-100);

  /* Scrap Share Popup */
  --background-color-share-list-hover: var(--color-creame);
  --border-share-list: 1px solid var(--color-light-bluish-gray);
  --border-share-list-hover: 1px solid var(--color-light-bluish-gray);
  --color-font-scrap-share-invite: var(--color-midnight-teal-100);
}

/*
 * Dark mode settings
 * Check the user's system settings and update the UI colors based on that
 * and update the variable values
 */
@media (prefers-color-scheme: dark) {
  :root {
    color-scheme: dark;
    --color-background-base: var(--color-background-dark);
    --color-background-main: var(--color-main-background-dark);
    --color-error: #ff4848;
    --color-text-body: var(--color-text-light);
    --color-text-body-inverse: var(--color-text-dark);
    --color-link-text: #90cff9;
    --color-background: var(--color-background-dark);
    --color-sidebar-background: var(--color-sidebar-dark);
    --color-sidebar-text: var(--color-text-light);
    --color-main-navigation-background: var(
      --color-main-navigation-background-dark
    );
    --color-main-navigation-text: var(--color-text-light);

    /* Hover effect */
    --color-background-hover: var(--color-black-300);

    /* Theme based colors */
    --color-theme-1: var(--color-white);
    --color-theme-2: var(--color-white);
    --color-theme-3: var(--color-black-300);
    --color-theme-4: var(--color-text-dark-200);
    --color-theme-5: var(--color-white);
    --color-theme-6: var(--color-white);
    --color-theme-7: var(--color-gray-dark);
    --color-theme-8: var(--color-light-bluish-gray);
    --color-theme-9: var(--color-background-dark);
    --color-theme-10: var(--color-grey-200);
    --color-theme-11: var(--color-black-400);
    --color-theme-12: var(--color-background-dark);
    --color-theme-13: var(--color-grey-200);
    --color-theme-14: var(--color-sky-blue);
    --color-theme-15: var(--color-grey);

    /* secondary navigation */
    --color-secondary-navigation-background: rgba(67, 67, 67, 0.5);
    --color-secondary-navigation-text: var(--color-text-light);

    /* card */
    --color-card-base: var(--color-midnight-teal);
    --color-card-base-other: #474747;
    --color-card-meta: #274150;
    --color-font-card-link-preview: var(--color-white);
    --color-card-meta-other: var(--color-black-300);
    --color-card-text: var(--color-text-light);
    --color-card-snippet: var(--color-background-dark);
    --color-card-meta-text: var(--color-sky-blue-100);
    --border-card-base: 1px solid var(--color-black);
    --border-card-blue: 1px solid #94bad1;
    --border-card-outer: 1px solid #474747;
    --card-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
    --color-card-link-border: #565656;
    --color-card-link-border-inner: #1e1e1e;
    --border-card-content-inner: 1px solid #1c2f3a;
    --border-card-content-outer: 1px solid #497892;
    --color-annotation-text: #161616;
    --color-card-font-user-name: #9e9e9e;
    --color-comment-icon: var(--color-gray-dark);
    --background-card-notes: var(--color-dark-blue);
    --color-card-new-snippet: var(--color-background-dark);
    --color-card-new-meta-other: var(--color-black-300);
    --color-card-new-base-other: #474747;
    --color-card-new-meta: #274150;
    --color-card-icon-hover:var(--color-hover-light);
    --color-background-card-highlights: var(--color-dark-yellowish-gray);
    --background-color-card-view-all-button: var(--color-background-dark);
    --border-card-footer: 1px solid var(--color-gray-dark-300);

    /* Borders */
    --primary-border: 1px solid var(--text-active);

    /* side navigation */
    --color-side-nav-filter-border: #1e1e1e;
    --color-shadow-side-nav-filter: var(--color-black-100);
    --color-font-side-nav-filter-label: var(--color-grey-200);
    --color-border-side-nav-dropdown-search: #1e1e1e;
    --color-background-side-nav-dropdown: var(--color-background-dark);
    --color-border-side-nav-dropdown: #1e1e1e;
    --color-font-side-nav-dropdown-highlight: var(--color-scrappi);
    --color-font-side-nav-view-more: var(--color-font-lightest);
    --color-font-side-nav-create: #919191;
    --color-font-side-nav-policy-text: #e0e0e0;
    --color-font-side-nav-item-label: var(--color-font-lightest);
    --color-font-side-nav-item-label-active: var(--color-white);
    --color-border-menu-items: #406a82;

    /* FILTERS LIST */
    --color-clear-text: var(--color-card-meta-text);
    --color-background-filter-list: var(
      --background-global-search-submit-button
    );
    --color-background-filter-list-border: 1px solid
      var(--color-shadow-side-nav-filter);
    --color-background-filter-list-box-shadow: inset 0px 0px 3px 2px
        rgba(0, 0, 0, 0.25),
      inset 0px 0px 0px 2px #1e1e1e;

    --border-card-outer--color: var(--color-gray-dark-300);
    --border-card-outer--color-blue: #406a82;
    --border-card-outer--color-other: #474747;
    --border-shadow-card: 1px solid #406a82;
    --border-shadow-card-other: 1px solid var(--color-gray-dark-300);

    /* Image Carousel */
    --color-background-carousel-button: var(--color-dark);

    /* checkbox colors */
    --color-checkbox-background: #454545;
    --color-checkbox-border: 1px solid #1c1c1c;
    --color-checkbox-label-background: rgba(235, 111, 25, 0.15);

    /* Theme Toggler colors */
    --color-font-theme-title: #999999;
    --color-theme-icon-fill: var(--color-white);
    --color-theme-active-background: rgba(235, 111, 25, 0.15);

    /* Main Navigation */
    --color-background-navigation-dropdown: var(--color-text-dark-200);
    --color-font-navigation: var(--color-white);
    --color-font-navigation-title: #999999;
    --filter-navigation-background-menu: drop-shadow(
      0px 6px 16px rgba(0, 0, 0, 0.8)
    );
    --color-font-navigation-username: #a6a6a6;
    --color-background-navigation-create-button: var(--color-scrappi);
    --color-font-navigation-create-button: var(--color-white);
    --border-font-navigation-create-button: 1px solid var(--color-scrappi);

    /* Global Search */
    --border-global-search-bar-suggestions: 1px solid #1a1919;
    --background-global-search-bar-input: var(--color-background-dark);
    --color-global-scrapper-bio: #aaaaaa;
    --border-view-profile-button: 1px solid transparent;
    --color-font-header-global-search: #999999;
    --color-font-global-search-item-count: var(--color-very-light-pink);
    --color-font-global-search-header-nav: var(--color-white);
    --color-text-global-search: #bababa;


    /* Collection Header */
    --border-outer-collection-card-footer: 1px solid #1e1e1e;
    --color-border-inner-collection-card-footer: var(--color-gray-dark-300);
    --border-top-collection-header: 1px solid #676767;
    --box-shadow-collection-header: 0px 4px 4px rgba(0, 0, 0, 0.25);
    --border-bottom-collection-header-head: 1px solid #1e1e1e;
    --border-collection-header-image: 1px solid #bababa;
    --color-font-edited-date-collection-header: #bcbcbc;
    --border-top-collection-header-footer: 1px solid var(--color-gray-dark-300);
    --color-background-collection-header-footer: var(--color-black-300);
    --border-collection-header-collaborator: 2px solid var(--color-black-300);
    --color-font-collection-header-icons: var(--color-grey-200);
    --background-global-search-submit-button: var(--color-text-dark-200);
    --color-font-collection-header-owner-name: var(--color-grey-200);
    --color-font-collection-header-owner-username: #888888;
    --color-collection-add-image:var(--color-card-base-other);
    --color-collection-header: var(--color-midnight-teal);
    --color-collection-header-inverse: var(--color-sky-blue);
    --color-collection-status-icon: var(--color-sky-blue);
    --color-collection-text: var(--color-text-dark-100);
    --color-collection-usename-text: var(--color-text-dark-100);
    --color-collection-desc-text: var(--color-white-500);
    --border-collection-collaborator-count: 2px solid var(--color-black-200);

    /* Loading Scrap Card */
    --color-background-loading-scrap-card: #474747;
    --border-top-loading-scrap-card: 1px solid #676767;
    --box-shadow-loading-scrap-card: 0px 4px 4px rgba(0, 0, 0, 0.5);
    --border-outer-header-loading-scrap-card: 1px solid #666666;
    --border-inner-header-loading-scrap-card: 1px solid #1e1e1e;
    --color-background-header-image-loading-scrap-card: #121212;
    --color-fill-image-loading-scrap-card: #404040;
    --color-background-header-content-loading-scrap-card: #444444;
    --border-top-outer-footer-loading-scrap-card: solid 1px #1e1e1e;
    --border-top-inner-footer-loading-scrap-card: solid 1px var(--color-gray-dark-300);
    --color-background-footer-loading-scrap-card: var(--color-black-300);
    --color-header-line-loading-scrap-card: #3d3d3d;
    --color-body-line-loading-scrap-card: #5b5b5b;
    --color-footer-line-loading-scrap-card: var(--color-gray-dark-300);
    --animate-line-loading-scrap-card: linear-gradient(
        97.49deg,
        rgba(243, 243, 243, 0) 38.09%,
        rgba(243, 243, 243, 0.2) 61.25%,
        rgba(243, 243, 243, 0) 80.04%
      ),
      #5b5b5b;
    /* Scrap Controls */
    --color-font-scrap-count-scrap-controls: var(--color-grey-200);
    --color-background-dropdown-scrap-controls: var(--color-background-dark);
    --filter-dropdown-scrap-controls: drop-shadow(
      0px 2px 4px rgba(0, 0, 0, 0.25)
    );
    --color-font-button-scrap-controls: var(--color-grey-200);
    --color-font-dropdown-label-scrap-controls: #999999;
    --color-background-dropdown-item-active-scrap-controls: #453326;
    --color-font-dropdown-item-scrap-controls: var(--color-white);
    --color-icon-dropdown-scrap-controls: var(--color-white);

    /* shadow */
    --shadow: 0 2px 4px 0 rgba(25, 25, 25, 0.5);

    /* Card Settings */
    --border-list-card-settings: 1px solid black;
    --box-shadow-list-card-settings: 0px 4px 4px rgba(0, 0, 0, 0.5);
    --color-background-list-card-settings: var(--color-background-dark);
    --color-font-card-settings: var(--color-white);
    --color-font-hover-card-settings: var(--color-scrappi);
    --color-background-hove-card-settings: rgba(235, 111, 25, 0.15);
    --color-icon-card-settings: #5086a4;
    --color-icon-card-settings--other: var(--color-gray-dark);
    --color-icon-card-settings-active: var(--color-white-500);
    --color-time-card-settings: var(--color-grey-200);
    --color-icon-meta-card-settings-other: var(--color-gray-dark);

    /* pills badge */
    --color-font--pill-label: #fff;
    --color-background-pill-hover: #453326;

    /* BATCH EDIT */
    --close-icon-fill-color: var(--color-font-lightest);
    --color-action-message: #bababa;

    /* New Popup */
    --border-new-popup: 1px solid var(--color-gray-dark-300);
    --color-border-line-new-popup: var(--color-gray-dark-300);
    --background-color-new-popup-header-icon: var(--color-background-dark);
    --filter-shadow-new-popup: drop-shadow(0px 6px 16px rgba(0, 0, 0, 0.8));
    --background-color-new-popup-header: var(--color-black-300);
    --border-close-icon-new-popup-header: 1px solid #474747;
    --background-color-new-popup-body: var(--color-black-200);
    --border-top-new-popup-body: 1px solid #1e1e1e;
    --background-color-new-popup-footer: var(--color-black-300);
    --border-new-popup-input: 1px solid #111111;
    --color-add-tags-title: #ffffff;
    /* ICON COLORS */
    --color-icon-tags: var(--color-grey-200);

    /* TEXT INPUT */
    --input-border-color: #111111;
    --input-tiptap-icon: var(--color-grey-200);
    --color-tiptap-background: var(--color-black-300);
    --color-invite-scrapper-email: var(--color-gray-dark);
    --color-invite-scrapper-message: var(--color-gray-dark);
    --input-otp-border-color: var(--color-white);

    /* Collection Collaboration Popup */
    --color-font-label-collaborator: var(--color-grey-200);
    --background-color-collaborator-input: var(--color-background-dark);
    --color-font-collaborator-input-placeholder: var(--color-grey);
    --color-font-collaborator-input: var(--color-white);
    --border-collaborator-input: 1px solid var(--text-active);
    --border-bottom-collaborator: 1px solid #1e1e1e;
    --border-top-collaborator: 1px solid var(--color-gray-dark-300);
    --background--copylink-input-new-popup: var(--color-background-dark);
    --copylink-button-new-popup: var(--color-scrappi);
    --copylink-button-border-new-popup: var(--color-gray-dark-300);
    --copylink-button-text-new-popup: #ffffff;
    --switchbutton-background-new-popup: var(--color-background-dark);
    --background-color-new-popup-tiptap: var(--color-background-dark);
    --color-new-popup-label: var(--color-grey-200);
    --bottom-collaborator-background: var(--color-black-300);
    /* New Popup Input Focus */
    --border-new-popup-input-focus: 1px solid var(--color-scrappi);

    /* PROFILE PAGE */
    --border-top-profile-card: 1px solid #676767;
    --border-bottom-profile-card: none;
    --box-shadow-profile-card: none;
    --profile-page-background: var(--color-background-dark);
    --profile-page-sidebar-border: 1px solid var(--color-gray-dark-300);
    --profile-page-form-input-background: var(--color-background-dark);
    --profile-page-form-input-username: #676767;
    --profile-page-password-cross-svg-fill: #bbbbbb;
    --profile-page-border: 1px solid #111111;

    /* Scrap Images Popup */
    --border-uploader-container-scrap-images: 1px solid
      var(--color-font-lightest);
    --box-shadow-uploader-container-scrap-images: inset 0px 0px 0px 2px #bababa;
    --color-font-uploader-button-scrap-images: var(--color-font-light-footer);
    --color-font-add-button-scrap-images: var(--color-text-body);
    --border-uploader-button-scrap-images: 1px solid var(--color-font-meta);
    --color-font-uploader-support-text-scrap-images: var(--color-font-meta);
    --border-scrap-saved-edit-button: 1px solid var(--color-text-body);
    --color-font-scrap-saved-edit-button: var(--color-text-body);

    /* New Popup Input Focus */
    --border-new-popup-input-focus: 1px solid var(--color-scrappi);
    /* Bulk Edit & Select */
    --bulk-input-text-color: #bababa;
    /* COLLECTION ORGANIZE MODE */
    --color-box-shadow-collection-organize: var(--color-black-100);
    --color-background-collection-organize: var(--color-black-300);

    /* Share Collection */
    --share-collection-switch-toggle-color: var(--color-grey-200);

    /* Tiptap */
    --color-font-tiptap-button: var(--color-grey-200);
    --background-color-tiptap: var(--color-background-dark);
    --color-font-tiptap-placeholder: var(--color-gray-dark);
    --background-color-tiptap-button-active: var(--color-gray-dark);

    /* Add Images Popup */
    --color-background-add-images: #474747;

    /* Collection Settings */
    --background-collection-settings-name-field: var(--color-background-dark);
    --background-color-collection-settings-view: #898989;
    --border-collection-settings-view-dropdown: 1px solid
      var(--color-background-dark);
    --color-font-collection-settings-view-dropdown-current: var(--color-white);
    --background-color-collection-settings-view-dropdown: var(--color-text-dark-200);
    --background-color-collection-settings-view-dropdown-item-hover: rgba(
      0,
      0,
      0,
      0.2
    );
    --color-font-collection-settings-delete-button: var(--color-grey-200);
    --color-icon-collection-settings-view-dropdown: var(--color-white);

    /* Image Cropper */
    --color-icon-image-cropper-control: var(--color-white-500);
    --color-font-image-cropper-cancel: var(--color-grey-200);

    /* TabSwitch Background */
    --color-tab-background: var(--color-gray-background);

    /* Auth Wrapper Background */
    --color-auth-background: #474747;
    --color-auth-divider: var(--color-gray-dark-300);

    /* Scrap Share Popup */
    --background-color-share-list-hover: var(--color-background-dark);
    --border-share-list: 1px solid var(--color-grey-200);
    --border-share-list-hover: 1px solid var(--color-black-400);
    --color-font-scrap-share-invite: var(--color-white);
  }
}

/*
 * Theme forced to be dark
 */
html[data-theme='dark'] {
  color-scheme: dark;
  /* Base */
  --color-background-base: var(--color-background-dark);
  --color-background-main: var(--color-main-background-dark);
  --color-error: #ff4848;
  --color-text-body: var(--color-text-light);
  --color-text-body-inverse: var(--color-text-dark);
  --color-link-text: #90cff9;
  --color-background: var(--color-background-dark);
  --color-sidebar-background: var(--color-sidebar-dark);
  --color-sidebar-text: var(--color-text-light);
  --color-main-navigation-background: var(
    --color-main-navigation-background-dark
  );
  --color-main-navigation-text: var(--color-text-light);

  /* Hover effect */
  --color-background-hover: var(--color-black-300);

  /* Theme based colors */
  --color-theme-1: var(--color-white);
  --color-theme-2: var(--color-white);
  --color-theme-3: var(--color-black-300);
  --color-theme-4: var(--color-text-dark-200);
  --color-theme-5: var(--color-white);
  --color-theme-6: var(--color-white);
  --color-theme-7: var(--color-gray-dark);
  --color-theme-8: var(--color-light-bluish-gray);
  --color-theme-9: var(--color-background-dark);
  --color-theme-10: var(--color-grey-200);
  --color-theme-11: var(--color-black-400);
  --color-theme-12: var(--color-background-dark);
  --color-theme-13: var(--color-grey-200);
  --color-theme-14: var(--color-sky-blue);
  --color-theme-15: var(--color-grey);

  /* secondary navigation */
  --color-secondary-navigation-background: rgba(67, 67, 67, 0.5);
  --color-secondary-navigation-text: var(--color-text-light);

  /* card */
  --color-card-base: var(--color-midnight-teal);
  --color-card-base-other: #474747;
  --color-card-meta: #274150;
  --color-card-meta-other: var(--color-black-300);
  --color-card-text: var(--color-text-light);
  --color-card-snippet: var(--color-background-dark);
  --color-card-meta-text: var(--color-sky-blue-100);
  --color-font-card-link-preview: var(--color-white);
  --border-card-base: 1px solid var(--color-black);
  --border-card-blue: 1px solid #94bad1;
  --border-card-outer: 1px solid #474747;
  --card-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  --color-card-link-border: #565656;
  --color-card-link-border-inner: #1e1e1e;
  --border-card-content-inner: 1px solid #1c2f3a;
  --border-card-content-outer: 1px solid #497892;
  --color-annotation-text: #161616;
  --color-card-font-user-name: #9e9e9e;
  --color-comment-icon: var(--color-gray-dark);
  --background-card-notes: var(--color-dark-blue);
  --color-card-new-snippet: var(--color-background-dark);
  --color-card-new-meta-other: var(--color-black-300);
  --color-card-new-base-other: #474747;
  --color-card-new-meta: #274150;
  --color-card-icon-hover:var(--color-hover-light);
  --color-background-card-highlights: var(--color-dark-yellowish-gray);
  --background-color-card-view-all-button: var(--color-background-dark);
  --border-card-footer: 1px solid var(--color-gray-dark-300);


  /* Borders */
  --primary-border: 1px solid var(--text-active);

  /* shadow */
  --shadow: 0 2px 4px 0 rgba(25, 25, 25, 0.5);

  /* side navigation */
  --color-side-nav-filter-border: #1e1e1e;
  --color-shadow-side-nav-filter: var(--color-black-100);
  --color-font-side-nav-filter-label: var(--color-grey-200);
  --color-border-side-nav-dropdown-search: #1e1e1e;
  --color-background-side-nav-dropdown: var(--color-background-dark);
  --color-border-side-nav-dropdown: #1e1e1e;
  --color-font-side-nav-dropdown-highlight: var(--color-scrappi);
  --color-font-side-nav-view-more: var(--color-font-lightest);
  --color-font-side-nav-create: #919191;
  --color-font-side-nav-policy-text: #e0e0e0;
  --color-font-side-nav-item-label: var(--color-font-lightest);
  --color-font-side-nav-item-label-active: var(--color-white);
  --color-border-menu-items: #406a82;

  /* FILTERS LIST */
  --color-clear-text: var(--color-card-meta-text);
  --color-background-filter-list: var(--background-global-search-submit-button);
  --color-background-filter-list-border: 1px solid
    var(--color-shadow-side-nav-filter);
  --color-background-filter-list-box-shadow: inset 0px 0px 3px 2px
      rgba(0, 0, 0, 0.25),
    inset 0px 0px 0px 2px #1e1e1e;

  --border-card-outer--color: var(--color-gray-dark-300);
  --border-card-outer--color-blue: #406a82;
  --border-card-outer--color-other: #474747;
  --border-shadow-card: 1px solid #406a82;
  --border-shadow-card-other: 1px solid var(--color-gray-dark-300);

  /* Image Carousel */
  --color-background-carousel-button: var(--color-dark);

  /* checkbox colors */
  --color-checkbox-background: #454545;
  --color-checkbox-border: 1px solid #1c1c1c;
  --color-checkbox-label-background: rgba(235, 111, 25, 0.15);

  /* Theme Toggler colors */
  --color-font-theme-title: #999999;
  --color-theme-icon-fill: var(--color-white);
  --color-theme-active-background: rgba(235, 111, 25, 0.15);

  /* Main Navigation */
  --color-background-navigation-dropdown: var(--color-text-dark-200);
  --color-font-navigation: var(--color-white);
  --color-font-navigation-title: #999999;
  --filter-navigation-background-menu: drop-shadow(
    0px 6px 16px rgba(0, 0, 0, 0.8)
  );
  --color-font-navigation-username: #a6a6a6;
  --color-background-navigation-create-button: var(--color-scrappi);
  --color-font-navigation-create-button: var(--color-white);
  --border-font-navigation-create-button: 1px solid var(--color-scrappi);

  /* Global Search */
  --border-global-search-bar-suggestions: 1px solid #1a1919;
  --background-global-search-bar-input: var(--color-background-dark);
  --color-global-scrapper-bio: #aaaaaa;
  --border-view-profile-button: 1px solid transparent;
  --color-font-header-global-search: #999999;
  --color-font-global-search-item-count: var(--color-very-light-pink);
  --color-font-global-search-header-nav: var(--color-white);
  --color-text-global-search: #bababa;

  /* Collection Header */
  --border-outer-collection-card-footer: 1px solid #1e1e1e;
  --color-border-inner-collection-card-footer: var(--color-gray-dark-300);
  --border-top-collection-header: 1px solid #676767;
  --box-shadow-collection-header: 0px 4px 4px rgba(0, 0, 0, 0.25);
  --border-bottom-collection-header-head: 1px solid #1e1e1e;
  --border-collection-header-image: 1px solid #bababa;
  --color-font-edited-date-collection-header: #bcbcbc;
  --border-top-collection-header-footer: 1px solid var(--color-gray-dark-300);
  --color-background-collection-header-footer: var(--color-black-300);
  --border-collection-header-collaborator: 2px solid var(--color-black-300);
  --color-font-collection-header-icons: var(--color-grey-200);
  --background-global-search-submit-button: var(--color-text-dark-200);
  --color-font-collection-header-owner-name: var(--color-grey-200);
  --color-font-collection-header-owner-username: #888888;
  --color-collection-add-image:var(--color-card-base-other);
  --color-collection-header: var(--color-midnight-teal);
  --color-collection-header-inverse: var(--color-sky-blue);
  --color-collection-status-icon: var(--color-sky-blue);
  --color-collection-text: var(--color-text-dark-100);
  --color-collection-usename-text: var(--color-text-dark-100);
  --color-collection-desc-text: var(--color-white-500);
  --border-collection-collaborator-count: 2px solid var(--color-black-200);

  /* Loading Scrap Card */
  --color-background-loading-scrap-card: #474747;
  --border-top-loading-scrap-card: 1px solid #676767;
  --box-shadow-loading-scrap-card: 0px 4px 4px rgba(0, 0, 0, 0.5);
  --border-outer-header-loading-scrap-card: 1px solid #666666;
  --border-inner-header-loading-scrap-card: 1px solid #1e1e1e;
  --color-background-header-image-loading-scrap-card: #121212;
  --color-fill-image-loading-scrap-card: #404040;
  --color-background-header-content-loading-scrap-card: #444444;
  --border-top-outer-footer-loading-scrap-card: solid 1px #1e1e1e;
  --border-top-inner-footer-loading-scrap-card: solid 1px var(--color-gray-dark-300);
  --color-background-footer-loading-scrap-card: var(--color-black-300);
  --color-header-line-loading-scrap-card: #3d3d3d;
  --color-body-line-loading-scrap-card: #5b5b5b;
  --color-footer-line-loading-scrap-card: var(--color-gray-dark-300);
  --animate-line-loading-scrap-card: linear-gradient(
      97.49deg,
      rgba(243, 243, 243, 0) 38.09%,
      rgba(243, 243, 243, 0.2) 61.25%,
      rgba(243, 243, 243, 0) 80.04%
    ),
    #5b5b5b;
  /* Scrap Controls */
  --color-font-scrap-count-scrap-controls: var(--color-grey-200);
  --color-background-dropdown-scrap-controls: var(--color-background-dark);
  --filter-dropdown-scrap-controls: drop-shadow(
    0px 2px 4px rgba(0, 0, 0, 0.25)
  );
  --color-font-button-scrap-controls: var(--color-grey-200);
  --color-font-dropdown-label-scrap-controls: #999999;
  --color-background-dropdown-item-active-scrap-controls: #453326;
  --color-font-dropdown-item-scrap-controls: var(--color-white);
  --color-icon-dropdown-scrap-controls: var(--color-white);

  /* Card Settings */
  --border-list-card-settings: 1px solid black;
  --box-shadow-list-card-settings: 0px 4px 4px rgba(0, 0, 0, 0.5);
  --color-background-list-card-settings: var(--color-background-dark);
  --color-font-card-settings: var(--color-white);
  --color-font-hover-card-settings: var(--color-scrappi);
  --color-background-hove-card-settings: rgba(235, 111, 25, 0.15);
  --color-icon-card-settings: #5086a4;
  --color-icon-card-settings--other: var(--color-gray-dark);
  --color-icon-card-settings-active: var(--color-white-500);
  --color-time-card-settings: var(--color-grey-200);
  --color-icon-meta-card-settings-other: var(--color-gray-dark);

  /* pills badge */
  --color-font--pill-label: #fff;
  --color-background-pill-hover: #453326;

  /* BATCH EDIT */
  --close-icon-fill-color: var(--color-font-lightest);
  --color-action-message: #bababa;

  /* New Popup */
  --border-new-popup: 1px solid var(--color-gray-dark-300);
  --color-border-line-new-popup: var(--color-gray-dark-300);
  --background-color-new-popup-header-icon: var(--color-background-dark);
  --filter-shadow-new-popup: drop-shadow(0px 6px 16px rgba(0, 0, 0, 0.8));
  --background-color-new-popup-header: var(--color-black-300);
  --border-close-icon-new-popup-header: 1px solid #474747;
  --background-color-new-popup-body: var(--color-black-200);
  --border-top-new-popup-body: 1px solid #1e1e1e;
  --background-color-new-popup-footer: var(--color-black-300);
  --border-new-popup-input: 1px solid #111111;
  --color-add-tags-title: #ffffff;

  /* ICON COLORS */
  --color-icon-tags: var(--color-grey-200);

  /* TEXT INPUT */
  --input-border-color: #111111;
  --input-tiptap-icon: var(--color-grey-200);
  --color-tiptap-background: var(--color-black-300);
  --color-invite-scrapper-email: var(--color-gray-dark);
  --color-invite-scrapper-message: var(--color-gray-dark);
  --input-otp-border-color: var(--color-white);

  /* Collection Collaboration Popup */
  --color-font-label-collaborator: var(--color-grey-200);
  --background-color-collaborator-input: var(--color-background-dark);
  --color-font-collaborator-input-placeholder: var(--color-grey);
  --color-font-collaborator-input: var(--color-white);
  --border-collaborator-input: 1px solid var(--text-active);
  --border-bottom-collaborator: 1px solid #1e1e1e;
  --border-top-collaborator: 1px solid var(--color-gray-dark-300);
  --background--copylink-input-new-popup: var(--color-background-dark);
  --copylink-button-new-popup: var(--color-scrappi);
  --copylink-button-border-new-popup: var(--color-gray-dark-300);
  --copylink-button-text-new-popup: #ffffff;
  --switchbutton-background-new-popup: var(--color-background-dark);
  --background-color-new-popup-tiptap: var(--color-background-dark);
  --color-new-popup-label: var(--color-grey-200);
  --bottom-collaborator-background: var(--color-black-300);
  /* New Popup Input Focus */
  --border-new-popup-input-focus: 1px solid var(--color-scrappi);

  /* PROFILE PAGE */
  --border-top-profile-card: 1px solid #676767;
  --border-bottom-profile-card: none;
  --box-shadow-profile-card: none;
  --profile-page-background: var(--color-background-dark);
  --profile-page-sidebar-border: 1px solid var(--color-gray-dark-300);
  --profile-page-form-input-background: var(--color-background-dark);
  --profile-page-form-input-username: #676767;
  --profile-page-password-cross-svg-fill: #bbbbbb;
  --profile-page-border: 1px solid #111111;

  /* Scrap Images Popup */
  --border-uploader-container-scrap-images: 1px solid var(--color-font-lightest);
  --box-shadow-uploader-container-scrap-images: inset 0px 0px 0px 2px #bababa;
  --color-font-uploader-button-scrap-images: var(--color-font-light-footer);
  --color-font-add-button-scrap-images: var(--color-text-body);
  --border-uploader-button-scrap-images: 1px solid var(--color-font-meta);
  --color-font-uploader-support-text-scrap-images: var(--color-font-meta);
  --border-new-popup-input-focus: 1px solid var(--color-scrappi);
  --border-scrap-saved-edit-button: 1px solid var(--color-text-body);
  --color-font-scrap-saved-edit-button: var(--color-text-body);

  /* Bulk Edit & Select */
  --bulk-input-text-color: #bababa;

  /* Share Collection */
  --share-collection-switch-toggle-color: var(--color-grey-200);
  /* COLLECTION ORGANIZE MODE */
  --color-box-shadow-collection-organize: var(--color-black-100);
  --color-background-collection-organize: var(--color-black-300);

  /* Tiptap */
  --color-font-tiptap-button: var(--color-grey-200);
  --background-color-tiptap: var(--color-background-dark);
  --color-font-tiptap-placeholder: var(--color-gray-dark);
  --background-color-tiptap-button-active: var(--color-gray-dark);

  /* Add Images Popup */
  --color-background-add-images: #474747;

  /* Collection Settings */
  --background-collection-settings-name-field: var(--color-background-dark);
  --background-color-collection-settings-view: #898989;
  --border-collection-settings-view-dropdown: 1px solid
    var(--color-background-dark);
  --color-font-collection-settings-view-dropdown-current: var(--color-white);
  --background-color-collection-settings-view-dropdown: var(--color-text-dark-200);
  --background-color-collection-settings-view-dropdown-item-hover: rgba(
    0,
    0,
    0,
    0.2
  );
  --color-font-collection-settings-delete-button: var(--color-grey-200);
  --color-icon-collection-settings-view-dropdown: var(--color-white);

  /* Image Cropper */
  --color-icon-image-cropper-control: var(--color-white-500);
  --color-font-image-cropper-cancel: var(--color-grey-200);

  /* TabSwitch Background */
  --color-tab-background: var(--color-gray-background);

  /* Auth Wrapper Background */
  --color-auth-background: #474747;
  --color-auth-divider: var(--color-gray-dark-300);

  /* Scrap Share Popup */
  --background-color-share-list-hover: var(--color-background-dark);
  --border-share-list: 1px solid var(--color-grey-200);
  --border-share-list-hover: 1px solid var(--color-black-400);
  --color-font-scrap-share-invite: var(--color-white);
}

/*
 * Theme forced to be light
 */
html[data-theme='light'] {
  color-scheme: light;
  /* Base theme variables */
  --color-background-base: #ffffff;
  --color-background-main: var(--color-main-background-light);
  --color-error: #ff0000;
  --color-text-body: var(--color-text-dark);
  --color-text-body-inverse: var(--color-text-light);
  --color-link-text: var(--color-link-color);
  --color-background: var(--color-background-light);
  --color-sidebar-background: var(--color-sidebar-light);
  --color-sidebar-text: var(--color-text-dark);
  --color-main-navigation-background: var(
    --color-main-navigation-background-light
  );
  --color-main-navigation-text: var(--color-text-dark);

  /* Hover effect */
  --color-background-hover: var(--color-white-600);

  /* Theme based colors */
  --color-theme-1: var(--color-midnight-teal-100);
  --color-theme-2: var(--color-midnight-teal-200);
  --color-theme-3: var(--color-white);
  --color-theme-4: var(--color-gray-background);
  --color-theme-5: var(--color-black);
  --color-theme-6: var(--color-font-light-footer);
  --color-theme-7: var(--color-light-grey-100);
  --color-theme-8: var(--color-grey);
  --color-theme-9: var(--color-white-400);
  --color-theme-10: #84929a;
  --color-theme-11: #dde2e4;
  --color-theme-12: var(--color-gray-background);
  --color-theme-13: var(--color-midnight-teal-200);
  --color-theme-14: var(--color-midnight-teal-200);
  --color-theme-15: var(--color-light-bluish-gray);

  /* secondary navigation */
  --color-secondary-navigation-background: rgba(255, 255, 255, 0.7);
  --color-secondary-navigation-text: var(--color-text-dark);

  /* card */
  --color-card-base: var(--color-sky-blue-100);
  --color-card-base-other: var(--color-gray-background);
  --color-card-meta: #bed6e5;
  --color-card-meta-other: var(--color-light-grey-100);
  --color-card-text: var(--color-text-dark);
  --color-card-snippet: #e0e0e0;
  --color-card-meta-text: var(--color-midnight-teal-200);
  --color-font-card-link-preview: var(--color-text-dark);
  --border-card-base: 1px solid #bababa;
  --border-card-blue: 1px solid #94bad1;
  --border-card-outer: 1px solid var(--color-white-200);
  --card-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  --color-card-link-border: #ffffff;
  --color-card-link-border-inner: #bababa;
  --border-card-content-inner: 1px solid #94bad1;
  --border-card-content-outer: 1px solid var(--color-white-200);
  --color-annotation-text: #161616;
  --color-card-font-user-name: #84929a;
  --color-comment-icon: var(--color-grey);
  --background-card-notes: var(--border-card-outer--color-blue);
  --color-card-new-snippet: var(--color-gray-background);
  --color-card-new-meta-other: var(--color-gray-background);
  --color-card-new-base-other: #ffffff;
  --color-card-new-meta: var(--color-sky-blue);
  --color-card-new-snippet:#F0F0F0;
  --color-card-new-meta-other:#F0F0F0;
  --color-card-new-base-other:#FFFFFF;
  --color-card-icon-hover:rgb(242, 253, 251);
  --color-background-card-highlights: var(--color-yellow-light);
  --background-color-card-view-all-button: var(--color-creame);
  --border-card-footer: 1px solid var(--color-light-bluish-gray);

  /* Borders */
  --primary-border: 1px solid #bababa;

  /* side navigation */
  --color-side-nav-filter-border: var(--color-light-grey-100);
  --color-shadow-side-nav-filter: #ffffff;
  --color-font-side-nav-filter-label: var(--color-text-dark);
  --color-border-side-nav-dropdown-search: var(--color-font-lightest);
  --color-background-side-nav-dropdown: #e0e0e0;
  --color-border-side-nav-dropdown: #bababa;
  --color-font-side-nav-dropdown-highlight: var(--color-creame);
  --color-font-side-nav-view-more: var(--color-font-light-footer);
  --color-font-side-nav-create: #585858;
  --color-font-side-nav-policy-text: var(--color-font-light-footer);
  --color-font-side-nav-item-label: var(--color-text-dark);
  --color-font-side-nav-item-label-active: var(--color-text-dark);
  --color-border-menu-items: #bababa;

  /* FILTERS LIST */
  --color-clear-text: var(--color-text-dark);
  --color-background-filter-list: #cccccc;
  --color-background-filter-list-border: 1px solid #eeeeee;
  --color-background-filter-list-box-shadow: inset 0px 0px 3px 1px
      rgba(0, 0, 0, 0.25),
    inset 0px 0px 0px 2px #bababa;

  --border-card-outer--color: #f1f6f9;
  --border-card-outer--color-blue: #f1f6f9;
  --border-card-outer--color-other: #e0e0e0;
  --border-shadow-card: 1px solid #f1f6f9;
  --border-shadow-card-other: 1px solid #f7f7f7;

  /* Image Carousel */
  --color-background-carousel-button: var(--color-white);

  /* checkbox colors */
  --color-checkbox-background: var(--color-white);
  --color-checkbox-border: var(--border-card-base);
  --color-checkbox-label-background: var(--color-creame);

  /* Theme Toggler colors */
  --color-font-theme-title: var(--color-font-light-footer);
  --color-theme-icon-fill: var(--color-font-light-footer);
  --color-theme-active-background: var(--color-creame);

  /* Main Navigation */
  --color-background-navigation-dropdown: var(--color-white);
  --color-font-navigation: #838383;
  --color-font-navigation-title: var(--color-font-light-footer);
  --filter-navigation-background-menu: drop-shadow(
    0px 2px 4px rgba(0, 0, 0, 0.25)
  );
  --color-font-navigation-username: var(--color-text-dark);
  --color-background-navigation-create-button: var(--color-creame);
  --color-font-navigation-create-button: #d46417;
  --border-font-navigation-create-button: 1px solid #f5b78c;

  /* Global Search */
  --border-global-search-bar-suggestions: 1px solid #bababa;
  --background-global-search-bar-input: var(--color-white);
  --color-global-scrapper-bio: var(--color-text-dark);
  --border-view-profile-button: 1px solid #f5b78c;
  --color-font-header-global-search: var(--color-font-light-footer);
  --color-font-global-search-item-count: var(--color-text-dark);
  --color-font-global-search-header-nav: var(--color-font-light-footer);
  --color-text-global-search: var(--color-text-dark);

  /* Collection Header */
  --border-outer-collection-card-footer: 1px solid var(--color-white-600);
  --color-border-inner-collection-card-footer: transparent;
  --border-top-collection-header: 1px solid #ffffff;
  --box-shadow-collection-header: 0px 2px 4px rgba(0, 0, 0, 0.25);
  --border-bottom-collection-header-head: 1px solid #c4c4c4;
  --border-collection-header-image: 1px solid #bababa;
  --color-font-edited-date-collection-header: #7e7e7e;
  --border-top-collection-header-footer: 1px solid #f7f7f7;
  --color-background-collection-header-footer: var(--color-light-grey-100);
  --border-collection-header-collaborator: 2px solid var(--color-light-grey-100);
  --color-font-collection-header-icons: var(--color-text-dark);
  --background-global-search-submit-button: #f1f1f1;
  --color-font-collection-header-owner-name: var(--color-black-100);
  --color-font-collection-header-owner-username: #888888;
  --color-collection-add-image: var(--color-white-400);
  --color-collection-header:var(--color-sky-blue);
  --color-collection-header-inverse: var(--color-midnight-teal);
  --color-collection-status-icon: var(--color-midnight-teal-200);
  --color-collection-text: var(--color-sky-blue);
  --color-collection-usename-text: var(--color-text-light-200);
  --color-collection-desc-text: var(--color-text-dark);
  --border-collection-collaborator-count: 2px solid var(--color-white);

  /* Loading Scrap Card */
  --color-background-loading-scrap-card: var(--color-gray-background);
  --border-top-loading-scrap-card: 1px solid #ffffff;
  --box-shadow-loading-scrap-card: 0px 2px 4px rgba(0, 0, 0, 0.25);
  --border-outer-header-loading-scrap-card: 1px solid #fff;
  --border-inner-header-loading-scrap-card: 1px solid #bababa;
  --color-background-header-image-loading-scrap-card: #fff;
  --color-fill-image-loading-scrap-card: #bdbdbd;
  --color-background-header-content-loading-scrap-card: #e6e6e6;
  --border-top-outer-footer-loading-scrap-card: solid 1px #c4c4c4;
  --border-top-inner-footer-loading-scrap-card: solid 1px #f7f7f7;
  --color-background-footer-loading-scrap-card: var(--color-light-grey-100);
  --color-header-line-loading-scrap-card: #c1c1c1;
  --color-body-line-loading-scrap-card: #cfcfcf;
  --color-footer-line-loading-scrap-card: #bababa;
  --animate-line-loading-scrap-card: linear-gradient(
      127.97deg,
      rgba(243, 243, 243, 0) 26.3%,
      rgba(243, 243, 243, 0.2) 54.62%,
      rgba(243, 243, 243, 0) 80.68%
    ),
    #bababa;
  /* Scrap Controls */
  --color-font-scrap-count-scrap-controls: var(--color-font-light-footer);
  --color-background-dropdown-scrap-controls: var(--color-white);
  --filter-dropdown-scrap-controls: drop-shadow(
    0px 2px 4px rgba(0, 0, 0, 0.25)
  );
  --color-font-button-scrap-controls: var(--color-text-dark);
  --color-font-dropdown-label-scrap-controls: var(--color-font-light-footer);
  --color-background-dropdown-item-active-scrap-controls: var(--color-creame);
  --color-font-dropdown-item-scrap-controls: #838383;
  --color-icon-dropdown-scrap-controls: var(--color-font-light-footer);

  /* Card Settings */
  --border-list-card-settings: 1px solid white;
  --box-shadow-list-card-settings: 0px 2px 4px rgba(0, 0, 0, 0.25);
  --color-background-list-card-settings: var(--color-white);
  --color-font-card-settings: #838383;
  --color-font-hover-card-settings: var(--color-white);
  --color-background-hove-card-settings: var(--color-scrappi);
  --color-icon-card-settings: #72a7c5;
  --color-icon-card-settings--other: var(--color-gray-dark);
  --color-icon-card-settings-active: var(--color-text-dark);
  --color-time-card-settings: var(--color-font-light-footer);
  --color-icon-meta-card-settings-other: var(--color-grey);

  /* pills badge */
  --color-font--pill-label: #838383;
  --color-background-pill-hover: transparent;

  /* BATCH EDIT */
  --close-icon-fill-color: var(--color-grey);
  --color-action-message: #585858;

  /* New Popup */
  --border-new-popup: none;
  --color-border-line-new-popup: #dde2e4;
  --background-color-new-popup-header-icon: var(--color-white-100);
  --filter-shadow-new-popup: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
  --background-color-new-popup-header: #fdfdfd;
  --border-close-icon-new-popup-header: 1px solid #e0e0e0;
  --background-color-new-popup-body: var(--color-white);
  --border-top-new-popup-body: 1px solid var(--color-light-grey-100);
  --background-color-new-popup-footer: #fdfdfd;
  --background--copylink-input-new-popup: var(--color-gray-background);
  --copylink-button-new-popup: var(--color-creame);
  --copylink-button-border-new-popup: #f5b78c;
  --copylink-button-text-new-popup: #d46417;
  --switchbutton-background-new-popup: #cccccc;
  --background-color-new-popup-tiptap: #e0e0e0;
  --color-new-popup-label: var(--color-text-dark);
  --border-new-popup-input: 1px solid #bababa;
  --color-invite-scrapper-email: #bababa;
  --color-invite-scrapper-message: var(--color-grey);
  --color-add-tags-title: var(--color-font-light-footer);

  /* ICON COLORS */
  --color-icon-tags: var(--color-grey);

  /* TEXT INPUT */
  --input-border-color: #bababa;
  --input-otp-border-color: #bababa;
  --input-tiptap-icon: var(--color-black);
  --color-tiptap-background: #e0e0e0;
  --background--copylink-input-new-popup: var(--color-gray-background);
  --copylink-button-new-popup: var(--color-creame);
  --copylink-button-border-new-popup: #f5b78c;
  --copylink-button-text-new-popup: #d46417;
  --switchbutton-background-new-popup: #cccccc;
  --background-color-new-popup-tiptap: #e0e0e0;
  --color-new-popup-label: var(--color-text-dark);

  /* Collection Collaboration Popup */
  --color-font-label-collaborator: var(--color-text-dark);
  --background-color-collaborator-input: var(--color-white);
  --color-font-collaborator-input-placeholder: var(--color-font-lightest);
  --color-font-collaborator-input: var(--color-text-dark);
  --border-collaborator-input: 1px solid var(--color-font-lightest);
  --border-bottom-collaborator: 1px solid var(--color-light-grey-100);
  --border-top-collaborator: 1px solid var(--color-white);
  --bottom-collaborator-background: #f1f1f1;
  /* Scrap Images Popup */
  --border-uploader-container-scrap-images: 1px solid var(--color-font-lightest);
  --box-shadow-uploader-container-scrap-images: inset 0px 0px 0px 2px #bababa;
  --color-font-uploader-button-scrap-images: var(--color-font-light-footer);
  --color-font-add-button-scrap-images: var(--color-font-light-footer);
  --border-uploader-button-scrap-images: 1px solid var(--color-font-meta);
  --color-font-uploader-support-text-scrap-images: var(--color-font-meta);
  --border-scrap-saved-edit-button: 1px solid var(--color-light-grey-100);
  --color-font-scrap-saved-edit-button: var(--color-font-light-footer);

  /* New Popup Input Focus */
  --border-new-popup-input-focus: 1px solid #bababa;

  /* PROFILE PAGE */
  --border-top-profile-card: none;
  --border-bottom-profile-card: 1px solid var(--color-light-grey-100);
  --box-shadow-profile-card: 0px 2px 4px rgb(0 0 0 / 25%);
  --profile-page-background: #cacaca;
  --profile-page-sidebar-border: 1px solid var(--color-light-grey-100);
  --profile-page-form-input-background: #ffffff;
  --profile-page-form-input-username: var(--color-light-grey-100);
  --profile-page-password-cross-svg-fill: var(--color-grey);
  --profile-page-border: 1px solid #bababa;

  /* Bulk Edit & Select */
  --bulk-input-text-color: var(--color-text-dark);

  /* Share Collection */
  --share-collection-switch-toggle-color: var(--color-gray-background);
  /* COLLECTION ORGANIZE MODE */
  --color-box-shadow-collection-organize: #bababa;
  --color-background-collection-organize: #cccccc;
  /* Tiptap */
  --color-font-tiptap-button: var(--color-black);
  --background-color-tiptap: #e0e0e0;
  --color-font-tiptap-placeholder: var(--color-grey);
  --background-color-tiptap-button-active: #e0e0e0;

  /* Add Images Popup */
  --color-background-add-images: #f1f1f1;

  /* Collection Settings */
  --background-collection-settings-name-field: #e0e0e0;
  --background-color-collection-settings-view: var(--color-white);
  --border-collection-settings-view-dropdown: 1px solid
    var(--color-font-lightest);
  --color-font-collection-settings-view-dropdown-current: #838383;
  --background-color-collection-settings-view-dropdown: var(--color-white);
  --background-color-collection-settings-view-dropdown-item-hover: var(
    --color-light
  );
  --color-font-collection-settings-delete-button: var(--color-text-dark);
  --color-icon-collection-settings-view-dropdown: var(--color-font-meta);

  /* Image Cropper */
  --color-icon-image-cropper-control: var(--color-black);
  --color-font-image-cropper-cancel: var(--color-grey);

  /* TabSwitch Background */
  --color-tab-background: var(--color-white);

  /* Auth Wrapper Background */
  --color-auth-background: var(--color-white);
  --color-auth-divider: var(--color-light-grey-100);

   /* Scrap Share Popup */
   --background-color-share-list-hover: var(--color-creame);
   --border-share-list: 1px solid var(--color-light-bluish-gray);
   --border-share-list-hover: 1px solid var(--color-light-bluish-gray);
   --color-font-scrap-share-invite: var(--color-midnight-teal-100);
}
