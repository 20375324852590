.card-link-preview {
  &__wrapper {
    border: 1px solid var(--color-card-link-border-inner);
    border-radius: var(--border-radius-small);
    padding-left: 0.5rem;
    background-color: var(--color-card-new-snippet);
    overflow: hidden;
    color: var(--color-text-body);

    .card__highlight {
      margin: -0.5rem -0.5rem 0.5rem;
      padding-top: 1.25rem;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &__container {
    border: 1px solid var(--color-card-link-border);
    border-radius: var(--border-radius-small);
    margin-bottom: 12px;;
  }

  &__image {
    &:not(.no-margin-preview) {
      margin: -0.5rem -0.5rem -1.9rem;
    }
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
    position: relative;
  }

  &__link {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 0.1rem;
    padding-bottom: 5px;
    margin-top: 2rem;
    &-favicon {
      margin-right: 0.1875rem;
      display: flex;

      img {
        width: 14px;
        height: 14px;
        object-fit: contain;
      }
    }
    &-icon {
      right: 0;
      line-height: 0;
      top: 0;
      margin-left: 8px;
      margin-top: unset;
      transform: translateY(1px);
    }
    a {
      font-size: 0.85rem;
      color: var(--color-link-color);
      line-height: 0.875rem;
      font-weight: var(--font-weight-semi_bold);

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__title {
    font-size: var(--font-normal);
    line-height: var(--font-large);
    font-weight: var(--font-weight-medium);
    color: var(--color-font-card-link-preview);
    margin: 0;
    padding-bottom: 5px;
  }
  &__description-wrapper {
    padding-bottom: 1rem;
    position: relative;
  }
  &__description {
    font-size: var(--font-smaller);
    line-height: 18px;
    font-weight: var(--font-weight-normal);
    color: var(--color-font-card-link-preview);
    margin: 0;
    .LinesEllipsis {
      &-ellipsis button { 
        font-size: var(--font-smaller);
        line-height: 18px;
        font-weight: var(--font-weight-normal);
        background-color: transparent;
        border: none;
        color: var(--color-scrappi);
        padding: 0;
      }
      
    }
    

  }

  &__price {
    font-size: 0.875rem;
    color: var(--color-text-body);
    margin: 0;
    padding-top: 0.333rem;
    font-weight: var(--font-weight-semi_bold);
    border-top: 1px solid #aeaeae;
    margin-bottom: 5px;
  }

  &__link,
  &__title,
  &__description {
    width: 100%;
    /* 
      * THIS CSS WILL MAKE THE CARDS TITLE, LINK AND DESCRIPTION TO BE ONE LINER WITH ELLIPSIS 
      * COMMENTED IN CASE NEEDS TO BE RE-INCLUDED
    */
    /* white-space: nowrap;
    text-overflow: ellipsis; */
    overflow: hidden;
  }
  &__video-btn {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.card-link {
  &__target {
    display: inline-block;
    width: 100%;
    padding-right: 0.5rem;
  }
  &__pdf {
    display: flex;
    justify-content: center;
    padding: 16px 10px 16px 16px;
    align-items: center;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    &__name {
      color: #797979;
      font-weight: var(--font-weight-medium);
      font-size: var(--font-small);
      line-height: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 300px;
    }
  }
}
