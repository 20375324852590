body {
  height: var(--screen-height);
}
.sl-container {
  padding-left: calc(270px);
  &.batch-visible {
    .card-base {
      pointer-events: none;
   
    }
  }
  @media screen and (max-width: 960px) {
    padding-left: 0;
  }

  &.no-filter {
    height: calc(var(--screen-height) - 86px);
  }
  @media screen and (max-width: 760px) {
    &.batch-visible {
      padding-top: 35px;
    }
  }

  &.collapsed {
    width: 100%;
    padding-left: 0;
  }
}

.sl-wrapper {
  width: 100%;
  height: 100%;
  padding: 0 1.2rem;
  overflow-y: auto;
}

.custom-scroll {
  padding-right: 4px;
  &__body {
    /*overflow-y: overlay !important;*/
    ::-webkit-scrollbar-button {
      /* background-color: yellow; */
    }
    /* Only IE */
    scrollbar-face-color: transparent;
    scrollbar-shadow-color: transparent;
    scrollbar-highlight-color: transparent;

    /* Only FireFox */
    scrollbar-color: transparent transparent;

    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }
    &:hover {
      /* Only IE */
      scrollbar-face-color: #b5b5b5;

      /* Only Firefox */
      scrollbar-color: #b5b5b5 transparent;

      /* only Chrome */
      &::-webkit-scrollbar-thumb {
        border-radius: 7px;
        background-color: #b5b5b5;
        &:hover {
          background-color: #f15a27a1;
        }
      }
    }
  }
}

.related {
  &__wrapper {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;

    .panel-details__wrapper {
      position: relative;
      height: auto;
      max-height: inherit;
      max-width: unset;
      border-radius: 2rem;
      max-width: 500px;
      margin: 2rem auto;
      box-shadow: 0 0 30px -8px rgba(0, 0, 0, 0.2);

      .panel-details__content-wrapper {
        height: auto;
      }
    }
  }
}

.collapsed {
  .panel-details__wrapper {
    .panel-detials__domain,
    .panel-details__edit-tags {
      display: none;
    }
  }
}

video::-webkit-media-controls {
  visibility: hidden;
}

video::-webkit-media-controls-enclosure {
  visibility: visible;
}

/* to restore the state of the feed on back */
.scrap-details {
  .sl-container {
    display: none;
  }
}
html {
  scroll-behavior: smooth;
}

