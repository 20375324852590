.pill {
  &-text {
    width: 100%;
    display: inline-block;
    color: var(--color-font--pill-label);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 4px;
    border-radius: var(--border-radius-small);
    &:hover {
      color: var(--color-scrappi);
      background-color: var(--color-background-pill-hover);
    }

  }
  &-label {
    cursor: pointer;
    font-size: var(--font-smaller);
    font-weight: var(--font-weight-medium);
    line-height: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

  }
  &-div {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    gap: 4px;

    &__nav {
      width: 100%;
      padding-left: 26px;
      margin-top: 70px;
    }
    @media screen and (max-width: 800px) {
      flex-wrap: nowrap;
    }
  }
}

.active-pill {
  background-color: var(--color-blue);
  color: var(--color-white);
  font-weight: bold;
}

/* .pill-label:hover{
  color:var(--color-scrappi) ;

} */