.status-page {
  padding: 16px;
  border-radius: 8px;
  width: 100%;
  max-width: 700px;
  margin: 1rem auto 1rem;
  color: var(--color-text-body);

  &__wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    border-radius: 8px;
    overflow: hidden;
  }

  &__item {
    box-sizing: border-box;
    padding: 2rem 1.5rem;
    border: var(--card-border);
    background: var(--color-card-new-base-other);
    display: flex;
    justify-content: space-between;

    &-title {
      font-size: 1.2rem;
    }
  }

  &__status {
    padding: 2px 10px;
    font-size: 0.8rem;
    color: #ffffff;
    font-weight: normal;
    border-radius: 15px;

    &--online {
      background-color: #00C878;
    }

    &--offline {
      background-color: #FF0000;
    }
  }
}