.smart-organize {
  padding: 1rem 1rem 2rem;
  border-bottom: 1px solid rgb(95, 95, 95, 0.4);
  color: var(--color-text-body);

  &__wrapper {
    color: var(--color-text-body);
  }

  &__heading {
    font-size: 2rem;
    color: var(--color-scrappi);
    margin: 0;
  }

  &__description {
    opacity: 0.7;
    margin: 0 0 1rem;
  }

  .button__secondary {
    margin: 0 0 1.5rem;
  }

  &__scraps {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;

    .scrap-card {
      height: 100%;
      max-width: 250px;

      .images-group__image {
        max-height: 200px;
      }
    }

    .scrap-card__header {
      >div {
        display: none;
      }
    }

    .scrap-card__footer {
      display: none;
    }

    .scrap-card__body {
      height: auto;
    }

    @media (max-width: 1024px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
