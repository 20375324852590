
.meta {
  border: 1px solid var(--color-card-meta);
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  box-shadow: inset 0 2px 1px -1px var(--border-card-outer--color-blue);
  color: var(--color-time-card-settings);

  &__wrapper {
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    font-size: 0.88rem;

    &.no-border {
      border: none;
    }

    >div {
      display: flex;
      flex-direction: row;
      padding: 0px;
      gap: 12px;
    }

    .scrap-share{
      &__copied{
        position: absolute;
      bottom: calc(-115% - 16px);
      right: -9px;
      width: max-content;
      pointer-events: none;
      background: #edfff1;
      color: var(--color-green);
      padding: 12px;
      display: flex;
      grid-gap: 6px;
      gap: 6px;
      align-items: center;
      border: 1px solid #28a745;
      border-radius: var(--border-radius-small);
      -webkit-user-select: none;
      user-select: none;
      z-index: 999;
      &::after {
        content: ' ';
        position: absolute;
        top: -9px;
        width: 15px;
        height: 15px;
        right: 11px;
        background-color: #edfff1;
        transform: rotate(45deg);
        z-index: 0;
        border: 1px solid #28a745;
        border-bottom: none;
        border-right: none;
      }
      }
    }
  }

  &__right {
    gap: 5px;

    .action-item {
      z-index: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius:unset;
      margin: 0rem;
      width: unset;
      min-width: unset;
      height: unset;
      min-height: unset;
      border: unset;
      border-radius:unset;
      color: var(--color-font-primary);
      cursor: pointer;
      background-color: unset;

      svg path{
        fill: var(--color-icon-card-settings--other)
      }
    }
    &--disable-hover{
      cursor: default;
    }
  }
  &__edit-button {
    background-color: transparent;
    border: none;
    padding: 0;
    line-height: 0;
    padding: 6px;
    svg {
      /* height: 17px; */
    }
  }
  .share-settings {
    padding: 6px;
    svg {
      height: 17px;
      width: 17px;
    }
  }
  
  &__comment-icon, .share-settings svg, &__edit-button {
    path {
      fill: var(--color-icon-card-settings);
    }
  }
  &__edit-button {
    &--trash {
      path {
        fill: unset;
      }
    }
    &--pencil {
      svg {
        height: 17px;
        width: 17px;
      }
    }
  }
  

  &__left {}

  &__user {
    display: inline-block;
    overflow: hidden;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: var(--color-scrappi);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-white);
    font-size: 10px;
    font-weight: bold;
  }
  &__user-avatar {
      width: 100%;
      height: 100%;
      object-fit: contain;
      cursor: pointer;
  }

  &__domain {
    color: #1583d8;
    font-weight: 400;
    text-decoration: underline;
  }

  &__date {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  &__tag {
    &-wrapper {
      z-index: 1;
      position: relative;
      padding: 0.2rem 0 0.8rem;
    }

    display: inline-block;
    padding: 0.3rem 0.8rem;
    border-radius: 1rem;
    background-color: #efefef;
    margin: 0.2rem 0.18rem;
    color: #6b6b6b;
    font-size: 0.8rem;
    transition: 0.2s;

    &:hover {
      background-color: #f15927;
      color: #ffffff;
    }
  }

  &__menu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-bottom: 15rem;
    background-color: rgba(255, 255, 255, 0.98);
    overflow-y: auto;
    z-index: 5;
  }

  &__stories {
    padding: 1.2rem 1rem 0;
  }

  &__story {
    padding: 0.4rem;

    label {
      margin-left: 0.4rem;
    }

    &:hover {
      background-color: #efefef;
    }
  }

  &__actions {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    background-color: #f15927;
    bottom: 0;
    left: 0;
    margin-top: 1rem;
    padding: 0 1.2rem;
    color: #ffffff;
    border-radius: 0.5rem;
  }

  &__add-to-current-story {
    border: none;
    font-size: 1rem;
    background-color: #f15927;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    opacity: 1;
    transition: 0.2s;
    cursor: pointer;
    width: 10rem;
    height: 1.8rem;

    @media screen and (min-width: 760px) {
      position: absolute;
      bottom: 1rem;
      right: 1rem;
    }

    @media screen and (min-width: 960px) {
      opacity: 0;
    }
  }

  &__add-to-story {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    font-size: 1rem;
    background-color: #ffffff;
    color: #303030;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 0;
    height: 0;
    opacity: 0;
    transition: 0.2s;
    cursor: pointer;

    a {
      color: #303030;
    }

    &:hover {
      filter: contrast(0.85);
    }
  }

  &__link {
    position: absolute;
    left: 1rem;
    bottom: 1rem;
    display: inline-block;
    text-decoration: none;
    font-size: 15px;
    background-color: #ffffff;
    color: #303030;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
    padding: 0.3rem 1.6rem 0.3rem 1rem;
    border-radius: 1rem;
    transition: all 0.2s;
    transform: translateY(-10px);
    opacity: 0;
    transition: 0.2s;
  }
}

.card__page,
.card__social,
.card__annotation,
.card__product {
  .meta {
    border: 1px solid var(--color-card-meta-other);
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
    box-shadow: inset 0 2px 1px -1px var(--border-card-outer--color);
  }
}

/* disable the links for the scraps that have CTA */
.card__page,
.card__product {
  .meta__link {
    display: none;
  }
}

/* hover to show the add to story link */
.card-base:hover {
  .meta__add-to-story {
    opacity: 1;
    width: 2.12rem;
    height: 2.12rem;
    z-index: 1;
  }

  .meta__add-to-current-story {
    opacity: 1;
    z-index: 1;
  }

  .meta__link {
    transform: translateY(0px);
    opacity: 1;
    z-index: 1;
  }
}

/* hide actions from draggable feed*/
.story__all-panels,
.story__panels {

  .meta__link,
  .card-details__hint,
  .meta__add-to-story {
    display: none;
    pointer-events: none;
  }

  .card-details__link-wrapper {
    display: none;
    pointer-events: none;
  }
}

