.onboard {
  &-info {
    width: 100%;
    max-width: 450px;
    font-size: 1rem;
    text-align: center;
    padding: 1.5rem;
    &.has-scraps {
      max-width: unset;
    }

    @media (max-width: 768px) {
      display: none;
    }

    &__message {
      font-weight: 600;
    }

    &__trigger {
      font-size: 3.5rem;
      background-color: var(--color-card-new-snippet);
      padding: 3rem 2.375rem;
      border-radius: 8px;
      margin: 1rem 0;
      display: flex;
      gap: 10px;
      justify-content: center;
      align-items: center;
    }

    &__download {
      border-top: 1px solid var(--color-gray-dark-200);
      margin-top: 1rem;
      padding-top: 1rem;

      &-button {
        font-size: var(--font-smaller);
        padding: 0.5rem 1rem;
        text-decoration: none;
      }

      .onboard-info__trigger {
        padding: 0;
        background-color: transparent;
      }
    }
  }

  &-key {
    &__modifier,
    &__character {
      display: flex;
      width: 100px;
      height: 90px;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--color-theme-2);
      border-radius: 8px;
      color: var(--color-theme-2);
    }

    &__modifier {
      padding: 8px;
      align-items: center;
      font-size: 1rem;
      justify-content: space-between;
      flex-direction: column;

      &--symbol {
        font-size: 1.7rem;
      }
      &--name {
        font-size: 0.8rem;
      }
    }
  }

  &__close-button {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    z-index: 1;

    @media (max-width: 768px) {
      display: none;
    }
  }

  &__step-count {
    position: absolute;
    top: 12px;
    right: 16px;
    font-size: 12px;
    line-height: 18px;
    padding: 2px 8px;
    border-radius: 16px;
    border: 1px solid var(--color-white);
    opacity: 0.60;
  }
}

.empty-clipboard {
  padding: 1rem;
  text-align: center;
  color: var(--color-text-body);

  span {
    margin-left: 6px;
    cursor: pointer;
    height: 20px;
    width: 20px;
  }

  &__link {
    color: var(--color-scrappi);
    text-decoration: underline;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      path {
        fill: var(--color-scrappi);
      }
    }
  }

  &-popup-content.new-popup--default-content {
    width: 100%;
    max-width: 500px;
  }
}