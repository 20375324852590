.marketing {
  width: 100%;
  text-align: center;
  color: var(--color-white);
  padding-top: 40px;
  padding-bottom: 200px;

  @media screen and (max-width: 768px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  &__text {
    font-size: 1.5rem;
  }

  .s-hero-img-left {
    max-width: 21.25rem;
    position: fixed;
    top: 10%;
    bottom: auto;
    left: 0;
    right: auto;
    z-index: -1;
  }

  .s-hero-img-right {
    max-width: 21.25rem;
    position: fixed;
    top: 10%;
    bottom: auto;
    left: auto;
    right: 0;
    z-index: -1;
  }

  /* hide the right and left image on mobile */
  @media screen and (max-width: 768px) {
    .s-hero-img-left {
      display: none;
    }
    .s-hero-img-right {
      display: none;
    }
  }

  .s-h1 {
    letter-spacing: -1.8px;
    font-size: 3.2rem;
    font-weight: 600;
    line-height: 1.07;
  }

  .sm-head {
    letter-spacing: 0;
    font-size: 1rem;
    font-weight: 300;
    top: -32px;
  }

  .s-hero_button {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    justify-content: center;
    margin-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
    display: flex;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  .s-orange-button {
    text-decoration: none;
    color: var(--color-white);
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    background-color: var(--color-scrappi);
    border-radius: 10px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 32px;
    font-weight: 500;
    transition: all .3s ease-in-out;
    display: flex;
  }

  .s-orange-button.white {
    color: var(--color-black);
    background-color: var(--color-white);
    height: 50px;
  }

  /* styles for collection page */
  &__collection-page {
    margin-bottom: -20px;
    margin-top: -20px;
    padding-bottom: 0;
    padding-top: 0;
  }

  &__collection-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start !important;
    margin-bottom: 40px;
    margin-left: 0 !important;
    margin-right: unset !important;
    background-color: var(--color-midnight-teal);
    max-width: 750px;
    padding: 12px;
    border-radius: 10px;
    font-size: 1.25rem;

    @media screen and (max-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }

    /* icons in the button */
    .s-orange-button-img {
      display: flex;
    }
  }

  &__cta-wrapper {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__between-buttons {
    font-size: 1.25rem;
    color: var(--color-white);
    padding: 8px 0 16px;
  }


}

/* price section */
.f-ani-vii_tab-panel-tile {
  grid-column-gap: 1.875rem;
  grid-row-gap: 1.875rem;
  background-color: #212121;
  border-radius: 10px;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding: 1.25rem;
  display: flex;
}

.s-new-price {
  /*max-width: 50rem;*/
  margin-left: auto;
  margin-right: auto;
  max-width: 530px;
}

.f-ani-vii_panel-mian {
  height: 100%;
  display: flex;


  @media screen and (max-width: 991px) {
    flex-flow: row;
    justify-content: flex-start;
    align-items: stretch;
  }

  @media screen and (max-width: 768px) {
    flex-flow: column;
  }
}

.f-ani-vii_panel-mian-price {
  flex-flow: column;
  width: 180px;
  min-width: 180px;
  display: flex;
  text-align: left;

  @media screen and (max-width: 768px) {
    width: 100%;
    text-align: center;
    gap: 16px;
  }
}

/* margin in between */
.f-ani-vii_panel-mian-line {
  background-color: rgba(146, 146, 146, .3);
  width: 1px;
  margin-left: 20px;
  margin-right: 20px;

  @media screen and (max-width: 991px) {
    width: 1.1px;
    height: auto;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
}

/* price details container */
.s-price-text {
  color: #eb6f19;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.2;
}

.s-price-number {
  color: #fff;
  font-size: 2.625rem;
  font-weight: 700;
  line-height: 1.2;
}

.f-ani-vii_panel-button {
  grid-column-gap: .75rem;
  grid-row-gap: .75rem;
  background-color: #eb6f19;
  color: #fff;
  text-decoration: none;
  border-radius: 60px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: .875rem;
  line-height: 1.2;
  transition: opacity .3s, transform .5s cubic-bezier(.68, -.55, .265, 1.55), box-shadow .3s cubic-bezier(.77, 0, .175, 1);
  display: flex;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 16px;
  }
}

.f-ani-vii_panel-button.wide, .f-ani-vii_panel-button.short.v3 {
  display: none;
}

.f-ani-vii_panel-mian-list {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  flex-direction: column;
  padding-left: 0;
  list-style-type: none;
  display: flex;
}

.f-ani-vii_panel-list-item {
  grid-column-gap: .75rem;
  grid-row-gap: .75rem;
  align-items: center;
  display: flex;
}

.f-ani-vii_panel-list-img {
  width: 24px;
  min-width: 24px;
  height: 24px;
  min-height: 24px;
}

.s-text-14px-14px {
  color: #fff;
  font-size: .875rem;
  line-height: 1;
}

/* story view styles */
.story.public-collection-page {
  display: flex;
  flex-direction: column;
  align-items: center;

  .s-hero_button.marketing__collection-buttons {
    margin-left: auto !important;
    margin-right: auto !important;
    max-width: 470px;

    .marketing__cta-wrapper {
      flex-direction: column;
    }

    .marketing__between-buttons {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}