.welcome {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  margin-top: 86px;
  flex-wrap: wrap;

  h2 {
    width: 100%;
    text-align: center;
  }

  &__image {
    display: block;
    max-height: 550px;
    width: auto;
  }
}

.instructions {
  width: 100%;
  max-width: 550px;
  
  img {
    width: 100%;
    height: auto;
  }
}