.tags-list {
  list-style: none;
  margin: 0;
  margin-bottom: 1rem;
  padding: 0;
  display: flex;
  flex-wrap: wrap;

  :first-child {
    margin-left: 0;
  }

  li {
    line-height: 18px;
    margin: 2px 2px;
    font-size: var(--font-small);
    font-weight: 900;
    color: var(--color-font-primary);
    padding: 2px 9px 3px;
    border-radius: 10px;
    border: solid 1px #d9d9d9;
    background-color: var(--color-white);
  }
  &__show-more {
    cursor: pointer;
    border-radius: 5px !important;
    border: none !important;
    &:hover {
      background-color: var(--color-hover-light);
    }
  }
  &__loading {
    font-size: var(--font-normal);
    color: var(--color-text-body);
  }

  &__wrapper {
    /* remove the label margin */
    .title__small:not(.exists-in__wrapper .title__small) {
      margin: 0;
    }

    .rw-widget-picker {
      display: block;
    }
    .rw-input-reset {
      max-width: 11.5rem !important;

      @media screen and (min-width: 1024px) {
        max-width: 12.5rem !important;
      }
    }
    &__count {
      margin: 0;
      margin-top: 0.2rem;
      font-size: 12px;
      & > .danger {
        color: red;
        font-weight: 400;
      }
    }

    .rw-multiselect-tag {
      background: none;
      font-size: 12px;
      font-weight: 900;
      color: #111;
      border: 1px solid #111;
      border-radius: 30px;
      padding: 0.2rem 0.7rem;
      position: relative;
    }
    .rw-multiselect-tag-btn {
      width: 100%;
      color: rgba(0, 0, 0, 0);
      left: 0;
      top: 0;
      height: 100%;
      margin-left: 0;
    }
  }

  &__with-icon {
    display: flex;
    align-items: center;
    margin-bottom: 0.875rem;

    .tags-list {
      margin-bottom: 0;
      margin-left: 6px;

      &__icon {
        width: 18px;
        height: 18px;
      }
    }

    .tags-list-item {
      background-color: transparent;
      border: 0;
      border-radius: 0;
      padding: 0;
      color: var(--color-text-body);
      font-weight: var(--font-weight-light);
      font-size: 0.875rem;
    }
  }

  &-item {
    span {
      text-decoration: none;
      color: inherit;
    }
    &.clickable span {
      cursor: pointer;
    }
  }
}
